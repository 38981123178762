import {
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import axios from 'axios';
import clsx from 'clsx';
import { useState } from 'react';

import { T } from '@/components/T';
import { toast } from '@/components/ui/toast';
import { useCurrency } from '@/hooks/useCurrency';
import { useSettings } from '@/hooks/useSettings';
import { planData } from '@/plan-data';

import type { AccountType } from '@/components/Modals/CreateAccount/types';
import type { AvailablePaymentMethods } from '@stripe/stripe-js';

interface ExpressCheckoutProps {
  setError: (error: string) => void;
  accountType: AccountType;
  periodId: number;
}

const ExpressCheckout = ({
  setError,
  accountType,
  periodId,
}: ExpressCheckoutProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [paymentMethodCount, setPaymentMethodCount] = useState<number | null>(
    null,
  );

  const { settings } = useSettings();

  const currency = useCurrency();

  if (!stripe || !elements) {
    return null;
  }

  // If we have no payment methods return from stripe, return null.
  if (paymentMethodCount !== null && paymentMethodCount <= 0) {
    return null;
  }

  const onConfirm = async () => {
    try {
      if (!stripe || !elements) {
        // Stripe.js hasn't loaded yet.

        return;
      }

      const { error: submitError } = await elements.submit();

      if (submitError) {
        setErrorMessage(submitError.message);

        return;
      }

      let currentPlanData = planData.premium;

      if (accountType === 'business') {
        currentPlanData = planData.business;
      }

      const conversionSource = localStorage.getItem('conversion_source');

      // Create the PaymentIntent and obtain clientSecret
      const res = await axios.post<{
        clientsecret: string;
        setupintent: string;
      }>(
        'v2/subscription/setupintent/create',
        {
          currency: currency?.id.toString(),
          period: periodId,
          country: settings?.country.id ?? 238,
          plan: currentPlanData.id,
          planData: currentPlanData,
          conversionSource,
        },
        {
          baseURL: process.env.NEXT_PUBLIC_API,
          withCredentials: true,
        },
      );

      const { clientsecret: clientSecret, setupintent: setupIntent } = res.data;

      // Get the full browser URL.
      const currentUrl = window.location.href;

      const returnUrl = new URL(currentUrl);

      returnUrl.searchParams.set('account', accountType);
      returnUrl.searchParams.set('type', 'paypal');
      returnUrl.searchParams.set('paymentStatus', 'pending');
      returnUrl.searchParams.set('setupIntentId', setupIntent);

      if (!setupIntent) {
        toast.error('Something went wrong. Please try again.');

        return;
      }

      const { error } = await stripe.confirmPayPalSetup(clientSecret, {
        return_url: returnUrl.toString(),
        mandate_data: {
          customer_acceptance: {
            type: 'online',
            online: {
              infer_from_client: true,
            },
          },
        },
      });

      if (error?.message) {
        // This point is only reached if there's an immediate error when
        // confirming the payment. Show the error to your customer (for example, payment details incomplete)
        toast.error(error.message);
        setError(error.message);
      }
    } catch (error) {
      toast.error(
        'An error occurred while creating your subscription. Please try again.',
      );
    }
  };

  return (
    <>
      <div className="relative w-full my-1.5 h-[2px] bg-gray-light-300">
        <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-futura font-bold bg-white px-2 dark:text-white dark:bg-gray-dark-300">
          <T>OR</T>
        </span>
      </div>
      <div className="h-fit min-h-11">
        {paymentMethodCount === null && (
          <div className="animate-pulse w-full h-[44px] bg-gray-light-200 dark:bg-gray-dark-200 rounded-[4px] block" />
        )}
        <ExpressCheckoutElement
          className={clsx(
            'express',
            paymentMethodCount === null && 'opacity-0 h-0',
          )}
          onConfirm={onConfirm}
          onReady={(e) => {
            if (typeof e.availablePaymentMethods !== 'undefined') {
              const methods = Object.keys(e.availablePaymentMethods).filter(
                (key) => {
                  if (e.availablePaymentMethods) {
                    return e.availablePaymentMethods[
                      key as keyof AvailablePaymentMethods
                    ];
                  }

                  return false;
                },
              );

              setPaymentMethodCount(methods.length);
            }
          }}
        />

        {errorMessage !== undefined && (
          <p className="alert-general-error mt-12">{errorMessage}</p>
        )}
      </div>
    </>
  );
};

export default ExpressCheckout;
