import { useEffect, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import { twMerge } from 'tailwind-merge';

import { updateActiveCampaignContact } from '@/api/activecampaign/update-contact';
import api from '@/api/legacyApi';
import { getAuthToken } from '@/auth';
import ArrowRight from '@/components/Icons/ArrowRight';
import Tick from '@/components/Icons/Tick';
import Modal from '@/components/Modals/Core';
import StackedTitles from '@/components/Modals/Sidebar/components/StackedTitles';
import useDirectionalAnimation from '@/components/Modals/Sidebar/hooks/useDirectionalAnimation';
import { useScreenContext } from '@/components/SteppedDialog/context/ScreenContext';
import { T } from '@/components/T';
import { Button } from '@/components/ui/button';
import { useTranslate } from '@/hooks/useTranslate';
import { parseEmoji } from '@/utils/helpers';

import Checkout from './checkout';
import CreateAccountForm from './form';
import { AccountType, CreateAccountModalScreenName } from './types';
import Welcome from './welcome';

import type { ContentType, VibeType } from './types';

export const TickList = ({
  items,
  className = '',
  liClassName = '',
  svgClassName = '',
}: {
  items: React.ReactNode[];
  className?: string;
  liClassName?: string;
  svgClassName?: string;
}) => (
  <ul
    className={twMerge(
      'list-none mb-6 space-y-2 text-gray-medium-400 dark:text-gray-medium-200 text-pretty',
      className,
    )}
  >
    {items.map((item, index) => (
      <li
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        className={twMerge(
          'flex justify-start items-center gap-1 text-base',
          liClassName,
        )}
      >
        <Tick className={twMerge('w-3.5 text-green', svgClassName)} /> {item}
      </li>
    ))}
  </ul>
);

const WhatsYourTheme = ({
  nextScreenName,
}: {
  nextScreenName: CreateAccountModalScreenName;
}) => {
  const { setScreen } = useScreenContext();
  const [contentTypes] = useLocalStorage<ContentType[]>('contentTypes', []);
  const [selectedContentType, setSelectedContentType] =
    useLocalStorage<ContentType | null>('selectedContentType', null);

  const sortedContentTypes = useMemo(() => {
    return ([...(contentTypes ?? [])] as ContentType[]).sort(
      (a, b) => a.rank - b.rank,
    );
  }, [contentTypes]);

  useEffect(() => {
    parseEmoji();
  }, [sortedContentTypes]);

  return (
    <>
      <Modal.Title className="text-black dark:text-white mb-6 text-xl">
        <T keyName="modal.createAccountWhatsYourTheme.title">
          What's Your Theme?
        </T>
        <span className="text-sm text-uppbeat block">
          <T>(Select one)</T>
        </span>
      </Modal.Title>
      <div className="gap-2 flex flex-wrap">
        {sortedContentTypes.map((contentType) => (
          <Button
            variant="secondary-modal"
            size="sm"
            borderRadius="full"
            key={contentType.id}
            className={
              selectedContentType && selectedContentType.id !== contentType.id
                ? 'opacity-50 hover:opacity-75 transition-opacity duration-200'
                : 'bg-gray-light-300 hover:bg-gray-light-300 dark:bg-gray-dark-100'
            }
            onClick={() => {
              setSelectedContentType(contentType);
              setScreen(nextScreenName);
            }}
          >
            {contentType.icon}
            {contentType.name}
          </Button>
        ))}
      </div>
    </>
  );
};

const WhatsYourVibe = ({
  nextScreenName,
  previousScreenName,
}: {
  nextScreenName: CreateAccountModalScreenName;
  previousScreenName: CreateAccountModalScreenName;
}) => {
  const { setScreen } = useScreenContext();
  const [vibeTypes] = useLocalStorage<VibeType[]>('vibeTypes', []);
  const [selectedVibeType, setSelectedVibeType] =
    useLocalStorage<VibeType | null>('selectedVibeType', null);

  const [selectedContentType] = useLocalStorage<ContentType | null>(
    'selectedContentType',
    null,
  );

  const sortedVibeTypes = useMemo(() => {
    return ([...(vibeTypes ?? [])] as VibeType[]).sort(
      (a, b) => a.rank - b.rank,
    );
  }, [vibeTypes]);

  useEffect(() => {
    parseEmoji();
  }, [sortedVibeTypes]);

  const handleClick = async () => {
    if (!selectedVibeType || !selectedContentType) {
      return;
    }

    const authToken = getAuthToken();
    const formData = new FormData();

    formData.append('vibe', `${selectedVibeType.id}`);
    formData.append('content', `${selectedContentType.id}`);

    try {
      await api(
        'user/user_data',
        {
          headers: {
            'X-Auth-Token': authToken ?? '',
          },
          body: formData,
        },
        {
          defaultContentType: false,
        },
      );
    } catch (err) {
      console.warn('Bad vibes');
    }

    updateActiveCampaignContact();

    setScreen(nextScreenName);
  };

  return (
    <>
      <Modal.Title className="text-black dark:text-white mb-6 text-xl">
        <T keyName="modal.createAccountWhatsYourTheme.title">
          What's Your Vibe?
        </T>
        <span className="text-sm text-uppbeat block">
          <T>(Select one)</T>
        </span>
      </Modal.Title>
      <div className="gap-2 flex flex-wrap">
        {sortedVibeTypes.map((vibeType) => (
          <Button
            variant="secondary-modal"
            size="sm"
            borderRadius="full"
            key={vibeType.id}
            onClick={() => {
              setSelectedVibeType(vibeType);
            }}
            className={
              selectedVibeType && selectedVibeType.id !== vibeType.id
                ? 'opacity-50 hover:opacity-75 transition-opacity duration-200'
                : 'bg-gray-light-300 hover:bg-gray-light-300 dark:bg-gray-dark-100'
            }
          >
            {vibeType.icon}
            {vibeType.name}
          </Button>
        ))}
      </div>
      <div className="flex justify-center my-4 gap-2">
        <Button
          variant="secondary-modal"
          borderRadius="full"
          onClick={() => {
            setScreen(previousScreenName);
          }}
          className="flex-shrink-0"
          type="button"
          size="icon"
        >
          <ArrowRight className="rotate-180" width={14} />
        </Button>
        <Button
          variant="uppbeat"
          onClick={handleClick}
          className="flex-1"
          type="submit"
          disabled={!selectedVibeType}
        >
          Done
        </Button>
      </div>
    </>
  );
};

const CreateAccountModalScreens = () => {
  const animationProps = useDirectionalAnimation();
  const { t } = useTranslate();
  const { setScreen } = useScreenContext();

  return (
    <>
      <Modal.Screen
        screenName={CreateAccountModalScreenName.Free}
        className="px-6 py-12"
        {...animationProps}
      >
        <Modal.Title className="text-black dark:text-white mb-6 text-xl">
          <T keyName="modal.createAccountFree.title">Create Free Account</T>
        </Modal.Title>
        <Modal.Description>
          <TickList
            items={[
              t('3 downloads (renewed monthly)'),
              t('No YouTube copyright claims'),
              t('Incredible beatmakers & artists'),
            ]}
          />
        </Modal.Description>
        <CreateAccountForm accountType={AccountType.Free} />
      </Modal.Screen>
      <Modal.Screen
        screenName={CreateAccountModalScreenName.Premium}
        className="px-6 py-12"
        {...animationProps}
      >
        <Modal.Title className="text-black dark:text-white mb-6 text-xl">
          <T keyName="modal.createAccountPremium.title">
            Create Premium Account
          </T>
        </Modal.Title>
        <Modal.Description>
          <TickList
            items={[
              t('Unlimited music & SFX downloads'),
              t('Safelist your YouTube channel'),
              t('100% catalog access'),
            ]}
          />
        </Modal.Description>
        <CreateAccountForm accountType={AccountType.Premium} />
      </Modal.Screen>
      <Modal.Screen
        screenName={CreateAccountModalScreenName.Business}
        className="px-6 py-12"
        {...animationProps}
      >
        <Modal.Title className="text-black dark:text-white mb-6 text-xl">
          <T keyName="modal.createAccountBusiness.title">
            Create Business Account
          </T>
        </Modal.Title>
        <Modal.Description>
          <p className="font-semibold text-black dark:text-white mb-4">
            <T keyName="modal.upsellBusiness.allBenefitsOfPremium">
              All the benefits of Premium, plus
            </T>
          </p>
          <TickList
            items={[
              t('License for organizations of any scale'),
              t('Covers digital ads & client content'),
              t('Safelist up to 10 YouTube channels'),
            ]}
          />
        </Modal.Description>
        <CreateAccountForm accountType={AccountType.Business} />
      </Modal.Screen>
      <Modal.Screen
        screenName={CreateAccountModalScreenName.CheckoutPremium}
        className="px-6 py-12"
        {...animationProps}
      >
        <StackedTitles
          title="Checkout"
          subtitle={
            <>
              Uppbeat <span className="!text-uppbeat">Premium</span>
            </>
          }
        />
        <Checkout
          accountType={AccountType.Premium}
          nextScreenName={CreateAccountModalScreenName.WhatsYourThemePremium}
        />
        {process.env.NODE_ENV !== 'production' && (
          <Button
            variant="secondary-modal"
            className="w-full"
            onClick={() => {
              setScreen(CreateAccountModalScreenName.WhatsYourThemePremium);
            }}
          >
            Skip
          </Button>
        )}
      </Modal.Screen>
      <Modal.Screen
        screenName={CreateAccountModalScreenName.CheckoutBusiness}
        className="px-6 py-12"
        {...animationProps}
      >
        <StackedTitles
          title="Checkout"
          subtitle={
            <>
              Uppbeat <span className="!text-business">Business</span>
            </>
          }
        />
        <Checkout
          accountType={AccountType.Business}
          nextScreenName={CreateAccountModalScreenName.WhatsYourThemeBusiness}
        />
      </Modal.Screen>
      <Modal.Screen
        screenName={CreateAccountModalScreenName.WhatsYourThemeFree}
        className="px-6 py-12"
        {...animationProps}
      >
        <WhatsYourTheme
          nextScreenName={CreateAccountModalScreenName.WhatsYourVibeFree}
        />
      </Modal.Screen>
      <Modal.Screen
        screenName={CreateAccountModalScreenName.WhatsYourThemePremium}
        className="px-6 py-12"
        {...animationProps}
      >
        <WhatsYourTheme
          nextScreenName={CreateAccountModalScreenName.WhatsYourVibePremium}
        />
      </Modal.Screen>
      <Modal.Screen
        screenName={CreateAccountModalScreenName.WhatsYourThemeBusiness}
        className="px-6 py-12"
        {...animationProps}
      >
        <WhatsYourTheme
          nextScreenName={CreateAccountModalScreenName.WhatsYourVibeBusiness}
        />
      </Modal.Screen>
      <Modal.Screen
        screenName={CreateAccountModalScreenName.WhatsYourVibeFree}
        className="px-6 py-12"
        {...animationProps}
      >
        <WhatsYourVibe
          nextScreenName={CreateAccountModalScreenName.WelcomeToUppbeat}
          previousScreenName={CreateAccountModalScreenName.WhatsYourThemeFree}
        />
      </Modal.Screen>
      <Modal.Screen
        screenName={CreateAccountModalScreenName.WhatsYourVibePremium}
        className="px-6 py-12"
        {...animationProps}
      >
        <WhatsYourVibe
          nextScreenName={CreateAccountModalScreenName.WelcomeToUppbeat}
          previousScreenName={
            CreateAccountModalScreenName.WhatsYourThemePremium
          }
        />
      </Modal.Screen>
      <Modal.Screen
        screenName={CreateAccountModalScreenName.WhatsYourVibeBusiness}
        className="px-6 py-12"
        {...animationProps}
      >
        <WhatsYourVibe
          nextScreenName={CreateAccountModalScreenName.WelcomeToUppbeat}
          previousScreenName={
            CreateAccountModalScreenName.WhatsYourThemeBusiness
          }
        />
      </Modal.Screen>
      <Modal.Screen
        screenName={CreateAccountModalScreenName.WelcomeToUppbeat}
        className="px-6 py-12"
        {...animationProps}
      >
        <Welcome />
      </Modal.Screen>
    </>
  );
};

export default CreateAccountModalScreens;
