import * as React from 'react';

const _MotionGraphics = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M0 9.09986C0 7.55349 1.2531 6.29991 2.79888 6.29991H6.29773C7.84351 6.29991 9.09662 7.55349 9.09662 9.09987V11.2C9.09662 12.7464 7.84351 14 6.29773 14H2.79889C1.25311 14 0 12.7464 0 11.2V9.09986Z"
        fill="dark:#fff #000"
      />
      <path
        d="M9.80156 9.69337C9.80156 9.1722 10.091 8.69418 10.5526 8.45271L11.9522 7.72074C12.8839 7.23343 14 7.9096 14 8.9614V10.9881C14 12.0399 12.8839 12.7161 11.9522 12.2288L10.5526 11.4968C10.091 11.2554 9.80156 10.7773 9.80156 10.2562V9.69337Z"
        fill="dark:#fff #000"
      />
      <path
        d="M10.8458 2.80003C10.8458 4.34645 9.59269 5.60007 8.04687 5.60007C6.50104 5.60007 5.24791 4.34645 5.24791 2.80003C5.24791 1.25362 6.50104 0 8.04687 0C9.59269 0 10.8458 1.25362 10.8458 2.80003Z"
        fill="dark:#fff #000"
      />
      <path
        d="M4.19844 3.49946C4.19844 4.65927 3.25859 5.59948 2.09922 5.59948C0.939852 5.59948 0 4.65927 0 3.49946C0 2.33965 0.939852 1.39943 2.09922 1.39943C3.25859 1.39943 4.19844 2.33965 4.19844 3.49946Z"
        fill="dark:#fff #000"
      />
    </svg>
  );
};

const MotionGraphics = React.memo(_MotionGraphics);

export const MotionGraphicsWithIconTag = () => {
  return (
    <i className="!size-3.5">
      <MotionGraphics />
    </i>
  );
};

export default MotionGraphics;
