import { clsx } from 'clsx';
import { useState } from 'react';

import { Facade } from './Facade';
import styles from './styles.module.css';
import { getVideo } from './videoList';
import { VideoPlayer } from './VideoPlayer';

import type { VideoTypes } from './videoList';
import type { CSSProperties } from 'react';

interface VideoProps {
  playInline?: boolean;
  className?: string;
  type: VideoTypes;
  hideTitle?: boolean;
  thumbnailStyle?: CSSProperties;
  hideTitleGradientBg?: boolean;
}

export const Video: React.FC<VideoProps> = ({
  type,
  playInline,
  className,
  hideTitle,
  thumbnailStyle,
  hideTitleGradientBg = false,
}) => {
  const [showVideo, setShowVideo] = useState(false);

  const video = getVideo(type);

  if (!video) {
    return null;
  }

  const facade = (
    <div
      className={clsx(
        styles.videoContainer,
        'rounded-2xl overflow-hidden flex justify-center items-center',
        className,
      )}
    >
      <Facade
        title={video.name}
        videoType="youtube"
        onClick={() => setShowVideo(true)}
        id={video.id}
        length={video.length}
        hideTitle={hideTitle}
        thumbnailStyle={thumbnailStyle}
        hideTitleGradientBg={hideTitleGradientBg}
      />
    </div>
  );

  if (playInline) {
    return (
      <div className={clsx(styles.videoContainer, className)}>
        {showVideo ? (
          <VideoPlayer
            id={video.id}
            playInline={playInline}
            hideVideo={() => setShowVideo(false)}
          />
        ) : (
          facade
        )}
      </div>
    );
  }

  return (
    <>
      {showVideo && (
        <VideoPlayer
          id={video.id}
          playInline={playInline}
          hideVideo={(e) => {
            e?.stopPropagation();
            setShowVideo(false);
          }}
        />
      )}

      {facade}
    </>
  );
};

export default Video;
