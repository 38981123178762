import { useContext } from 'react';
import { useMedia } from 'react-use';

import { SidebarContext } from '@/providers/SidebarProvider';

const NewBadge = () => {
  const { isSidebarToggled } = useContext(SidebarContext);

  const isSidebarAutoToggled = useMedia('(max-width: 991px)', false);
  const isSidebarClosed = isSidebarToggled || isSidebarAutoToggled;

  if (isSidebarClosed) {
    return null;
  }

  return (
    <div className="inline-flex items-center border focus:ring-offset-2 dark:focus:ring-neutral-300 bg-uppbeat text-white dark:bg-white border-neutral-200 px-1.5 py-0 self-center uppercase dark:text-uppbeat font-bold font-heading whitespace-nowrap ml-auto text-xs rounded-md leading-5">
      New
    </div>
  );
};

export default NewBadge;
