import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useQueryClient } from '@tanstack/react-query';
import { clsx } from 'clsx';
import dynamic from 'next/dynamic';
import localFont from 'next/font/local';
import { Router, useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

import { clearTracks } from '@/actions/trackAction';
import { getAuthToken } from '@/auth';
import AdminBar from '@/components/AdminBar';
import Footer from '@/components/Footer';
import IssueBanner from '@/components/IssueBanner';
import {
  AccountModalNames,
  useAccountModals,
} from '@/components/Modals/Account';
import { GoPremiumModalScreenName } from '@/components/Modals/GoPremium/types';
import PagePopupQueue from '@/components/PagePopupQueue';
import TopBar from '@/components/TopBar';
import FeedbackSurvey from '@/components/TypeformSurvey';
import { UBAlerts } from '@/components/UBAlerts';
import UBSidebar from '@/components/UBSidebar';
import { toast } from '@/components/ui/toast';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppState } from '@/hooks/useAppState';
import { useAuthToken } from '@/hooks/useAuthToken';
import useFileFormat from '@/hooks/useFileFormat';
import { useTranslate } from '@/hooks/useTranslate';
import { useUpdateActiveCampaign } from '@/hooks/useUpdateActiveCampaign';
import { ScrollProvider } from '@/providers/ScrollProvider';
import { asPath, stripStaticPrefix } from '@/utils/router';

import { MainLayout } from './MainLayout';

import type { ReactNode } from 'react';

const brandonText = localFont({
  src: '../../fonts/brandon-text-latin.woff2',
  variable: '--font-heading',
});

const FooterPlayer = dynamic(() => import('../FooterPlayer/WithCurrentModal'), {
  ssr: false,
});

const BlogNav = dynamic(
  () => import('../../features/blog/components/Layout/BlogNav'),
  { ssr: true },
);

const OldAdminBar = dynamic(() => import('../TopBar/AdminBar'));

interface AppContainerProps {
  children: ReactNode;
}

export const AppContainer = ({ children }: AppContainerProps) => {
  const router = useRouter();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const modal = useAppState('modal');

  const userAuth = useAppState('userAuth');
  const dispatch = useAppDispatch();
  const isFree = asPath(router) === '/free';
  const authToken = getAuthToken();

  const queryClient = useQueryClient();
  const { t } = useTranslate();

  const isBlog = router.pathname.startsWith('/blog');

  const shouldUseFastlyDownload = useFeatureIsOn('fastly-downloads');

  const { setCurrentModal, currentModal } = useAccountModals();

  const { fileFormat, setFileFormat } = useFileFormat();

  useAuthToken();

  useUpdateActiveCampaign({
    authToken,
    refreshInterval: 600000,
    refetchOnWindowFocus: true,
  });

  // Handle opening modals based on query params
  useEffect(() => {
    const { query } = router;

    if (query.paymentStatus !== 'pending') {
      return;
    }

    if (currentModal) {
      return;
    }

    // If the user is not logged in, show the login modal
    if (!userAuth?.isLoggedIn && userAuth?.hasLoaded) {
      setCurrentModal(AccountModalNames.Login);

      // Replace the current URL with the cleaned path, to ensure the users can close the modal.
      const cleanedPath = router.asPath.split('?')[0];

      void router.replace(cleanedPath, undefined, { shallow: true });

      toast.error('Please log in to continue with your payment');

      return;
    }

    if (router.isReady && Object.keys(query).length > 0) {
      const modalAndScreen = {
        modal: AccountModalNames.GoPremium,
        screen: GoPremiumModalScreenName.GoPremiumCheckout,
      };

      if (query.account === 'business') {
        modalAndScreen.modal = AccountModalNames.GoPremium;
        modalAndScreen.screen = GoPremiumModalScreenName.GoBusinessCheckout;
      }

      setCurrentModal(modalAndScreen.modal, modalAndScreen.screen);
    }
  }, [
    router,
    setCurrentModal,
    currentModal,
    userAuth?.isLoggedIn,
    userAuth?.hasLoaded,
  ]);

  useEffect(() => {
    const onRouteChangeStart = (
      nextPathname: string,
      query: { shallow?: boolean },
    ) => {
      const isSamePath =
        stripStaticPrefix(nextPathname).split('?')[0] ===
        asPath(router).split('?')[0];

      if (!query.shallow && !isSamePath) {
        dispatch(clearTracks());
      }
    };

    const onRouteChangeComplete = (
      _url: string,
      query: { shallow?: boolean },
    ) => {
      if (!query.shallow) {
        scrollContainerRef.current?.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant',
        });
      }
    };

    // Clear the tracks on route change to avoid flicker of old tracks on new
    // page. To not clear if shallow routing to a variant of the same page
    // (e.g. when selecting filters that change the query params).
    Router.events.on('routeChangeStart', onRouteChangeStart);

    // Scroll main container to the top on route change.
    Router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
      router.events.off('routeChangeComplete', onRouteChangeStart);
    };
  }, [dispatch, router]);

  return (
    <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>
        {`
          :root {
            --font-heading: ${brandonText.style.fontFamily};
          }
        `}
      </style>

      <MainLayout>
        {userAuth?.isAdmin && <OldAdminBar />}
        <UBAlerts />
        <ScrollProvider scrollContainerRef={scrollContainerRef}>
          <main
            className={clsx(
              'main-content-section relative',
              modal && 'no-scroll',
              isFree && 'w-full',
            )}
          >
            <IssueBanner />
            <div
              className={clsx(
                'main-layout-container App',
                isFree ? 'main-layout-fixed' : 'main-layout-grid',
              )}
            >
              {!isBlog && <UBSidebar />}
              <div
                className="main-content-div @container"
                ref={scrollContainerRef}
              >
                <PagePopupQueue />

                {isBlog && <BlogNav />}
                <TopBar
                  queryClient={queryClient}
                  isFree={isFree}
                  t={t}
                  shouldUseFastlyDownload={shouldUseFastlyDownload}
                  setCurrentModal={setCurrentModal}
                  fileFormat={fileFormat}
                  setFileFormat={setFileFormat}
                />
                {children}
                {!router.pathname.includes('/help-center') &&
                  !router.pathname.includes('/browse/') &&
                  !router.pathname.includes('/music/') &&
                  !router.pathname.includes('/sfx/') && <Footer />}
              </div>
            </div>
            {(process.env.NODE_ENV === 'development' || userAuth?.isAdmin) && (
              <AdminBar />
            )}
          </main>
        </ScrollProvider>
        <FooterPlayer />
        <FeedbackSurvey />
      </MainLayout>
    </GoogleOAuthProvider>
  );
};
