import { zodResolver } from '@hookform/resolvers/zod';
import orderBy from 'lodash/orderBy';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  addExistingPlaylistTrack,
  addNewPlaylistTrack,
} from '@/actions/favouriteAction';
import { playlistCreated } from '@/actions/generalAction';
import Error from '@/components/Form/Error';
import Input from '@/components/Form/Input';
import Select from '@/components/Form/Select';
import { useSidebarModalContext } from '@/components/Modals/Sidebar/context/SidebarModalContext';
import { T } from '@/components/T';
import { Button, Loading } from '@/components/ui/button';
import { toast } from '@/components/ui/toast';
import { useSettings } from '@/hooks/useSettings';

import type { Details, Playlist } from './types';

interface FormProps {
  details: Details;
}

const playlistSchema = z.object({
  playlistName: z.string().min(1, 'Please enter a title for the playlist'),
  existingId: z.string().optional(),
});

type PlaylistFormValues = z.infer<typeof playlistSchema>;

const Form = ({ details }: FormProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm<PlaylistFormValues>({
    resolver: zodResolver(playlistSchema),
    defaultValues: {
      playlistName: `${details.track.artist.name} - ${details.track.name}`,
      existingId: '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const { settings, invalidateSettings } = useSettings();
  const { onOpenChange } = useSidebarModalContext();

  const [playlistExists, setPlaylistExists] = useState(false);
  const playlists = useMemo(() => {
    const _playlists = orderBy<Playlist>(
      settings?.playlists_data ?? [],
      ['name'],
      ['asc'],
    );

    if (_playlists.length > 0) {
      setPlaylistExists(true);
      setValue('existingId', _playlists[0].uuid);
    }

    return _playlists;
  }, [setValue, settings?.playlists_data]);

  const handleCreateNewPlaylist = (name: string) => {
    addNewPlaylistTrack(details.track.id, 1, name, details.contextId)
      .then((data) => {
        invalidateSettings();

        if (data && typeof data === 'object') {
          onOpenChange(false);
          toast.success(`Playlist ${name} created`);
        } else {
          toast.error('Sorry, something went wrong. Please try again later.');
        }

        setIsLoading(false);
        void playlistCreated(name);
      })
      .catch(() => {
        setIsLoading(false);
        toast.error('Sorry, something went wrong. Please try again later.');
      });
  };

  const handleAddToExistingPlaylist = (id: string) => {
    const foundPlaylist = playlists.find((playlist) => playlist.uuid === id);
    const playlistName = foundPlaylist ? foundPlaylist.name : '';

    addExistingPlaylistTrack(details.track.id, 1, id, details.contextId)
      .then((data) => {
        if (data) {
          setIsLoading(false);
          onOpenChange(false);
          toast.success(`${details.track.name} added to ${playlistName}`);
        }
      })
      .catch(() => {
        setIsLoading(false);
        toast.error('Sorry, something went wrong. Please try again later.');
      });
  };

  const onSubmit = (data: PlaylistFormValues) => {
    setIsLoading(true);

    if (playlistExists) {
      if (!data.existingId) {
        setError('existingId', {
          message: 'Please select a playlist',
        });
        setIsLoading(false);

        return;
      }

      handleAddToExistingPlaylist(data.existingId);
    } else if (data.playlistName) {
      handleCreateNewPlaylist(data.playlistName);
    }
  };

  const updatePlaylistType = () => {
    setPlaylistExists(!playlistExists);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="col-margin">
        {playlistExists ? (
          <>
            {isLoading && (
              <Select register={register} name="existingId">
                <option className="placeholder-text">
                  Loading playlists...
                </option>
              </Select>
            )}
            {!isLoading && (
              <div>
                <Select
                  register={register}
                  name="existingId"
                  hasError={!!errors.existingId}
                >
                  <option className="placeholder-text" value="">
                    <T>Select Playlist</T>
                  </option>
                  {playlists.map((playlist) => (
                    <option
                      key={playlist.uuid}
                      value={playlist.uuid}
                      title={playlist.name}
                    >
                      <T>{playlist.name.substring(0, 100)}</T>
                      {playlist.name.length > 100 ? '...' : ''}
                    </option>
                  ))}
                </Select>
                {errors.existingId && (
                  <Error>{errors.existingId?.message}</Error>
                )}
              </div>
            )}
          </>
        ) : (
          <div>
            <Input
              register={register}
              name="playlistName"
              placeholder="Playlist Name"
              hasError={!!errors.playlistName}
            />
            {errors.playlistName && (
              <Error>{errors.playlistName?.message}</Error>
            )}
          </div>
        )}
      </div>
      {playlistExists && (
        <span className="playlist-toggle-text">
          <T>Or</T>{' '}
          <button type="button" className="link" onClick={updatePlaylistType}>
            <T>create a new playlist</T>
          </button>
        </span>
      )}
      {!playlistExists && playlists.length > 0 && (
        <span className="playlist-toggle-text">
          Or{' '}
          <button type="button" className="link" onClick={updatePlaylistType}>
            <T>choose an existing playlist</T>
          </button>
        </span>
      )}
      <Button
        variant="uppbeat"
        className="w-full mt-12"
        disabled={isLoading}
        type={isLoading ? 'button' : 'submit'}
      >
        <Loading visible={isLoading} />
        Add To Playlist
      </Button>
    </form>
  );
};

export default Form;
