import Toolbar from '@/components/AdminBar/Toolbar';
import SettingsCog from '@/components/Icons/SettingsCog';
import Popover from '@/components/Popover';

import ToggleCDN from './ToggleCDN';
import ToggleRelevancy from './ToggleRelevancy';
import ToggleSEOData from './ToggleSEOData';
import ToggleTaxonomyData from './ToggleTaxonomyData';

const AdminSettings = () => {
  return (
    <Popover.Container
      className="dark:bg-gray-dark-400 border-solid dark:border-gray-dark-100 rounded-2xl mb-3"
      avoidCollisions
    >
      <Popover.Trigger>
        <Toolbar.Button>
          <SettingsCog />
        </Toolbar.Button>
      </Popover.Trigger>
      <Popover.Content>
        <div>
          <h4 className="font-heading text-base mb-2">Settings</h4>
          <ToggleCDN />
          <ToggleRelevancy />
          <ToggleTaxonomyData />
          <ToggleSEOData />
        </div>
      </Popover.Content>
    </Popover.Container>
  );
};

export default AdminSettings;
