import { clsx } from 'clsx';

import { Modal } from '@/components/Modal';

import styles from './styles.module.css';

import type { ReactEventHandler } from 'react';

export interface VideoPlayerProps {
  id: string;
  playInline?: boolean;
  hideVideo?: ReactEventHandler<HTMLDialogElement>;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  id,
  playInline,
  hideVideo,
}) => {
  const iframe = (
    <div
      className={clsx(
        styles.videoContainer,
        !playInline ? styles.isModal : undefined,
      )}
    >
      <iframe
        title="Video"
        src={`https://www.youtube.com/embed/${id}?rel=0&modestbranding=1&autohide=1&showinfo=0&autoplay=1`}
        frameBorder="0"
        allow="autoplay"
        allowFullScreen
        // eslint-disable-next-line @eslint-react/dom/no-unsafe-iframe-sandbox
        sandbox="allow-scripts allow-popups allow-same-origin"
      />
    </div>
  );

  if (playInline) {
    return iframe;
  }

  return <Modal onClose={hideVideo}>{iframe}</Modal>;
};
