import * as Portal from '@radix-ui/react-portal';
import clsx from 'clsx';
import { MessageSquarePlus } from 'lucide-react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useMeasure } from 'react-use';

import { Button } from '@/components/ui/button';
import { useIsClient } from '@/hooks/useIsClient';

const Widget = dynamic(
  () => import('@typeform/embed-react').then((mod) => mod.Widget),
  {
    ssr: false,
  },
);

const width = '20vw';

const TypeformSurvey = memo(
  ({ id, children }: { id: string; children: React.ReactNode }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const ref = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const isClient = useIsClient();

    const widgetKey =
      hasSubmitted && !isOpen ? 'widget-closed-and-submitted' : 'widget-open';

    const [widgetRef, widgetRect] = useMeasure<HTMLDivElement>();

    const handleOpen = useCallback(() => {
      setIsOpen(true);
    }, []);

    const handleClose = useCallback(() => {
      setIsOpen(false);
      buttonRef.current?.focus();
    }, []);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          handleClose();
        }
      };

      const handleEscapeKey = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          handleClose();
        }
      };

      if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscapeKey);
      }

      return () => {
        if (isOpen) {
          document.removeEventListener('mousedown', handleClickOutside);
          document.removeEventListener('keydown', handleEscapeKey);
        }
      };
    }, [ref, isOpen, handleClose]);

    useEffect(() => {
      if (!isOpen && buttonRef.current) {
        buttonRef.current.focus();
      }
    }, [isOpen]);

    const handleSubmit = useCallback(() => {
      setHasSubmitted(true);
    }, []);

    // Reset hasSubmitted when the widget is closed and submitted
    useEffect(() => {
      if (widgetKey === 'widget-closed-and-submitted') {
        // eslint-disable-next-line @eslint-react/hooks-extra/no-direct-set-state-in-use-effect
        setHasSubmitted(false);
      }
    }, [widgetKey]);

    if (!isClient) {
      return null;
    }

    return (
      <Portal.Root
        className="fixed top-0 right-0 z-20 h-screen pointer-events-none flex flex-col justify-start items-start"
        ref={ref}
        role="complementary"
        aria-label="Feedback survey"
      >
        <div
          className="relative flex flex-row items-start justify-start origin-center mb-0 md:mb-20 mt-20 h-full pointer-events-none"
          style={{ '--popup-width': width } as React.CSSProperties}
        >
          {!!widgetRect.width && (
            <Button
              ref={buttonRef}
              variant="uppbeat"
              borderRadius="sm"
              className={clsx(
                'font-sans text-sm font-bold rounded-l-none px-2 py-4 flex items-center gap-2 [writing-mode:vertical-lr] rotate-180 h-auto w-auto translate-x-[var(--button-x)] transition-transform duration-300 ease-in-out pointer-events-auto',
              )}
              style={
                {
                  '--button-x': isOpen ? '0' : `${widgetRect.width}px`,
                } as React.CSSProperties
              }
              onClick={isOpen ? handleClose : handleOpen}
              aria-expanded={isOpen}
              aria-controls="survey-widget"
              aria-haspopup="dialog"
            >
              <MessageSquarePlus
                className="fill-none size-5 rotate-180"
                aria-hidden="true"
              />
              {children}
            </Button>
          )}

          <div
            className={clsx(
              'overflow-hidden h-full w-[var(--widget-width)] transition-[clip-path] duration-300 ease-in-out',
              isOpen
                ? '[clip-path:inset(0_0_0_0)]'
                : '[clip-path:inset(0_0_0_100%)]',
            )}
            style={
              {
                '--widget-width': `${widgetRect.width}px`,
              } as React.CSSProperties
            }
            id="survey-widget"
            role="dialog"
            aria-modal="true"
            aria-hidden={!isOpen}
          >
            <div className="relative h-full overflow-hidden pointer-events-auto">
              <div
                ref={widgetRef}
                className="absolute top-0 left-0 h-[50vh] w-[75vw] md:h-[500px] md:w-[var(--popup-width)] min-h-[500px] min-w-[300px] max-w-[500px] max-h-full [&_iframe]:!rounded-none [&_iframe]:!rounded-bl-lg md:aspect-[2/3]"
              >
                <Widget
                  key={widgetKey}
                  className="w-full h-full"
                  inlineOnMobile
                  id={id}
                  onSubmit={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </Portal.Root>
    );
  },
);

const FeedbackSurvey = () => {
  const router = useRouter();

  if (router.asPath.startsWith('/motion-graphics')) {
    return <TypeformSurvey id="IVGGrWzT">Give feedback</TypeformSurvey>;
  }

  return null;
};

export default FeedbackSurvey;
