/**
 * Set the status of a popup in localStorage.
 *
 * `true` means the popup has been dismissed and should not be shown again.
 *
 * `false` means the popup has not yet been dismissed and can be shown.
 */
export const setAsDismissedInLocalStorage = ({
  localStorageKey,
}: {
  localStorageKey: string;
}) => {
  if (typeof localStorage !== 'undefined') {
    try {
      localStorage.setItem(localStorageKey, JSON.stringify(true));
    } catch (error) {
      console.warn('Failed to write to localStorage', error);
    }
  }
};
