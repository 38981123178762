export const shouldNotShowOnCurrentPage = ({
  popupExcludeSlug,
  pathname,
}: {
  popupExcludeSlug?: string | RegExp;
  pathname: string;
}) => {
  if (!popupExcludeSlug) {
    return false;
  }

  return !!pathname.match(popupExcludeSlug);
};
