import useTWScreens from '@/hooks/useTWScreens';
import { cn } from '@/lib/utils';

import type { PopUpVariant } from './PagePopup';
import type { ReactNode } from 'react';

interface PopupMediaProps {
  className?: string;
  children: ReactNode;
  variant?: PopUpVariant;
  videoAspectRatio?: number;
}

export const PopupMedia = ({
  className,
  children,
  variant = 'default',
  videoAspectRatio,
}: PopupMediaProps) => {
  const { isMd } = useTWScreens();

  return (
    <div
      className={cn(
        'relative h-auto',
        // Desktop widths
        isMd &&
          variant === 'default' &&
          'w-[298px]  rounded-lg overflow-hidden',
        isMd && variant === 'banner-img-pu' && 'w-[360px]',
        // Mobile width
        !isMd && 'w-full',
        !isMd && variant === 'default' && 'rounded-lg overflow-hidden',
        className,
      )}
      style={{ aspectRatio: videoAspectRatio ?? 298 / 160 }}
    >
      {children}
      {variant === 'banner-img-pu' && (
        <div className="absolute inset-0 bg-gradient-to-b from-black/50 to-transparent h-12" />
      )}
    </div>
  );
};
