import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/utils';

const textVariants = cva(
  'text-gray-medium-400 dark:text-gray-light-200 text-base',
  {
    variants: {
      variant: {
        default: 'text-gray-medium-400 dark:text-gray-light-200',
        modal: 'text-gray-medium-400 dark:text-gray-medium-200',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface TextProps
  extends React.HTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof textVariants> {
  asChild?: boolean;
}

const Text = React.forwardRef<HTMLParagraphElement, TextProps>(
  ({ className, asChild = false, variant, ...props }, ref) => {
    const Comp = asChild ? Slot : 'p';

    return (
      <Comp
        ref={ref}
        className={cn(textVariants({ variant }), className)}
        {...props}
      />
    );
  },
);

Text.displayName = 'Text';

export { Text, textVariants };
