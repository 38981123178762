import { Toaster as SonnerToast, toast } from 'sonner';
import { twMerge } from 'tailwind-merge';

type ToasterProps = typeof SonnerToast;

const Toaster: ToasterProps = ({ toastOptions, ...props }) => {
  return (
    <SonnerToast
      position="bottom-right"
      visibleToasts={4}
      cn={twMerge}
      pauseWhenPageIsHidden
      {...props}
      toastOptions={{
        ...toastOptions,
        unstyled: true,
        classNames: {
          ...toastOptions?.classNames,
          toast:
            'text-md border-solid border border-gray-light-300 font-futura flex gap-2 items-center bg-white text-gray-100 dark:bg-gray-dark-400 dark:border-gray-dark-200 dark:text-white rounded-lg shadow p-4 w-[length:var(--width)]',
          error: 'bg-red dark:bg-red text-white dark:text-white border-0',
          success: 'bg-green dark:bg-green text-white dark:text-white border-0',
          actionButton:
            'border border-solid border-white rounded-lg p-1.5 text-sm text-nowrap cursor-pointer',
        },
      }}
    />
  );
};

export { Toaster, toast };
