import clsx from 'clsx';
import { motion, usePresence } from 'framer-motion';
import { useEffect } from 'react';

import Modal from '@/components/Modals/Core';

interface InteractiveSidebarContainerProps {
  handleCloseSidebar: (velocity?: number) => void;
  children: React.ReactNode;
}

const SHARED_CLASSNAMES =
  '@container bg-white dark:bg-gray-dark-300 text-black dark:text-white sm:max-w-[340px] w-[calc(100%-40px)] h-full fixed top-0 right-0 z-[9999] overflow-x-hidden overflow-y-auto';

const InteractiveSidebarContainer = ({
  handleCloseSidebar,
  children,
}: InteractiveSidebarContainerProps) => {
  return (
    <Modal.Content asChild forceMount>
      <motion.div
        key="content"
        className={SHARED_CLASSNAMES}
        initial={{ x: '100%' }}
        animate={{ x: 0 }}
        exit={{ x: '100%' }}
        // TODO: re-enable when safari error is fixed:
        // drag="x"
        dragConstraints={{ left: 0 }}
        dragTransition={{
          bounceStiffness: 600,
          bounceDamping: 100,
        }}
        dragElastic={0}
        dragSnapToOrigin
        onDragEnd={(_event, info) => {
          // Ignore instance where a user might scroll down at a slight diagonal and accidently close the sidebar
          if (info.velocity.y > info.velocity.x || Math.abs(info.delta.y) > 5) {
            return;
          }

          if (info.velocity.x > 200 || info.point.x > 250) {
            // Close if dragged significantly to the right
            handleCloseSidebar();
          }
        }}
      >
        {children}
      </motion.div>
    </Modal.Content>
  );
};

interface NonInteractiveSidebarContainerProps {
  children: React.ReactNode;
}

const NonInteractiveSidebarContainer = ({
  children,
}: NonInteractiveSidebarContainerProps) => {
  const [isPresent, safeToRemove] = usePresence();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !isPresent && setTimeout(safeToRemove, 350);
  }, [isPresent, safeToRemove]);

  return (
    <Modal.Content asChild forceMount>
      <div
        className={clsx(SHARED_CLASSNAMES, {
          'animate-slideOutToRight': !isPresent,
          'animate-slideInFromRight': isPresent,
        })}
      >
        {children}
      </div>
    </Modal.Content>
  );
};

interface SidebarContainerProps
  extends InteractiveSidebarContainerProps,
    NonInteractiveSidebarContainerProps {
  interactive?: boolean;
}

const SidebarContainer = ({
  handleCloseSidebar,
  interactive,
  children,
}: SidebarContainerProps) => {
  if (interactive) {
    return (
      <InteractiveSidebarContainer handleCloseSidebar={handleCloseSidebar}>
        {children}
      </InteractiveSidebarContainer>
    );
  }

  return (
    <NonInteractiveSidebarContainer>{children}</NonInteractiveSidebarContainer>
  );
};

export default SidebarContainer;
