import { useMemo } from 'react';

import { HypertuneProvider } from '@/generated/hypertune.react';
import { useMGBetaEligibility } from '@/hooks/useMGBetaEligibility';
import { useSettings } from '@/hooks/useSettings';

import type { Rec3 } from '@/generated/hypertune';

const isValidUserData = (data: Rec3): data is Required<Rec3> => {
  if (!data) {
    return false;
  }

  const requiredProps: Record<keyof Required<Rec3>, string> = {
    id: 'string',
    country: 'string',
    isGoogleUser: 'boolean',
    isEmailVerified: 'boolean',
    isPremium: 'boolean',
    isBusiness: 'boolean',
    currency: 'string',
    isAuthenticated: 'boolean',
    hasOptedInMotionGraphics: 'boolean',
  };

  return Object.entries(requiredProps).every(
    ([prop, type]) => typeof data[prop as keyof Rec3] === type,
  );
};

export const AppHypertuneProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { settings } = useSettings();
  const betaEligibility = useMGBetaEligibility();
  const user = settings?.user;

  const userData: Rec3 = useMemo(() => {
    let id = user?.id?.toString();

    if (typeof id === 'undefined' && typeof window !== 'undefined') {
      let userHash = localStorage.getItem('user-hash');

      if (!userHash) {
        userHash = crypto.randomUUID();
        localStorage.setItem('user-hash', userHash);
      }

      id = userHash;
    }

    return {
      id: id ?? '',
      currency: settings?.currency?.code ?? '',
      country: user?.country ?? '',
      isGoogleUser: !!user?.isGoogleUser,
      isEmailVerified: !!user?.isEmailVerified,
      isPremium: !!settings?.isPremium,
      isBusiness: !!settings?.isBusiness,
      isAuthenticated: !!user?.is_authenticated,
      hasOptedInMotionGraphics: betaEligibility.hasOptedIn,
    };
  }, [
    user?.id,
    user?.country,
    user?.isGoogleUser,
    user?.isEmailVerified,
    settings?.isPremium,
    settings?.isBusiness,
    user?.is_authenticated,
    settings?.currency?.code,
    betaEligibility.hasOptedIn,
  ]);

  return (
    <HypertuneProvider
      createSourceOptions={{
        token: process.env.NEXT_PUBLIC_HYPERTUNE_TOKEN!,
        shouldRefreshInitData: true,
        initDataRefreshIntervalMs: 60_000,
      }}
      rootArgs={{
        context: {
          environment:
            (process.env.NEXT_PUBLIC_ENV as
              | 'local'
              | 'production'
              | 'preprod'
              | 'hades') ?? 'production',
          user: userData,
          betas: { motionGraphics: betaEligibility.hasOptedIn },
        },
      }}
    >
      {children}
    </HypertuneProvider>
  );
};
