import * as Tooltip from '@radix-ui/react-tooltip';
import { cva } from 'class-variance-authority';
import { useState } from 'react';

import { cn } from '@/lib/utils';

import type { VariantProps } from 'class-variance-authority';

const tooltipContentVariants = cva(
  "data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade text-violet11 select-none rounded-lg px-[15px] py-[10px] text-[15px] leading-none drop-shadow-lg will-change-[transform,opacity] z-[9999] max-w-[292px] after:content-[''] after:bottom-0 after:transform after:translate-y-1 after:w-4 after:h-4 after:absolute after:rotate-45 after:-z-10 border border-solid",
  {
    variants: {
      variant: {
        light:
          'bg-white dark:bg-gray-dark-100 dark:text-white after:bg-white dark:after:bg-gray-dark-100 border-gray-light-200 dark:border-gray-dark-200',
        dark: 'bg-gray-dark-100 dark:bg-white text-gray-light-400 dark:text-gray-medium-400 border-none dark:border-gray-dark-200',
      },
      error: {
        hasError: 'after:left-1/2 after:-translate-x-1/2',
        none: 'after:right-0 after:-translate-x-[calc(var(--radix-tooltip-trigger-width)/2-50%)]',
      },
    },
    compoundVariants: [{ variant: 'light', error: 'none' }],
    defaultVariants: {
      variant: 'light',
      error: 'none',
    },
  },
);

const tooltipArrowVariants = cva('fill-current', {
  variants: {
    variant: {
      default: 'text-white dark:text-gray-dark-300',
      inverted: 'text-gray-dark-300 dark:text-white',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

interface TooltipProps {
  trigger: React.ReactNode;
  children: React.ReactNode;
  hasError?: boolean;
  triggerStyles?: string;
  delayDuration?: number;
  variantProps?: VariantProps<typeof tooltipContentVariants>;
}

const ToolTip = ({
  trigger,
  children,
  hasError = false,
  triggerStyles,
  delayDuration,
  variantProps,
}: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Tooltip.Provider delayDuration={delayDuration}>
      <Tooltip.Root onOpenChange={(value) => setIsOpen(value)} open={isOpen}>
        <Tooltip.Trigger
          asChild
          onClick={(event) => {
            event.preventDefault();
          }}
        >
          <button
            type="button"
            className={
              hasError
                ? undefined
                : cn(
                    'text-sm text-right block ml-auto text-gray-medium-300 cursor-help mt-1 px-3',
                    triggerStyles,
                  )
            }
          >
            {trigger}
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            onPointerDownOutside={(event) => {
              event.preventDefault();
            }}
            className={cn(
              tooltipContentVariants({
                variant: variantProps?.variant,
                error: hasError ? 'hasError' : 'none',
              }),
            )}
            sideOffset={10}
            collisionPadding={20}
            align={hasError ? 'center' : 'end'}
          >
            {children}
            <Tooltip.Arrow
              className={cn(
                tooltipArrowVariants({
                  variant:
                    variantProps?.variant === 'light' ? 'default' : 'inverted',
                }),
                'mx-16',
              )}
              width={9}
              height={4}
            />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default ToolTip;
