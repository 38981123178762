import { mergeCollectionHits } from '@/features/SearchBar/utils/mergeCollections';

import type { AssetType } from '@/features/SearchBar/types';
import type { GenericSearchResult } from '@/features/SearchPage/types';
import type {
  SearchResponse,
  SearchResponseHit,
} from 'typesense/lib/Typesense/Documents';
import type { MultiSearchResponse } from 'typesense/lib/Typesense/MultiSearch';

const normalizeString = (str: string): string => {
  return str
    .toLowerCase()
    .replace(/[\s-]+/g, '') // Remove all whitespace and hyphens
    .trim();
};

const getFuzzyMatch = (
  hits: SearchResponseHit<GenericSearchResult>[],
  query: string,
) => {
  const normalizedQuery = normalizeString(query);

  return hits.filter((item) => {
    if (!item.document.name) {
      return false;
    }

    const normalizedName = normalizeString(item.document.name);

    return normalizedName === normalizedQuery;
  })[0];
};

export const getSuggestedResults = async (
  query: string,
  assetType: AssetType,
): Promise<
  [SearchResponse<GenericSearchResult>[], SearchResponse<GenericSearchResult>[]]
> => {
  const params: {
    searches: {
      q: string;
      collection: string;
      query_by: string;
      per_page: number;
      min_len_1typo?: number;
      num_typos?: number;
    }[];
  } = { searches: [] };

  switch (assetType) {
    case 'sfx':
      params.searches = [
        {
          q: query,
          collection: 'sfxcollections',
          query_by: 'name, keywords',
          per_page: 12,
          min_len_1typo: 6,
          num_typos: 1,
        },
        {
          q: query,
          collection: 'sfxstyles',
          query_by: 'name',
          per_page: 12,
          min_len_1typo: 6,
          num_typos: 1,
        },
        {
          q: query,
          collection: 'sfxtags',
          query_by: 'name',
          per_page: 16,
          min_len_1typo: 6,
          num_typos: 1,
        },
        ...(query.length > 2
          ? [
              {
                q: query,
                collection: 'searchredirects',
                query_by: 'name',
                per_page: 10,
                num_typos: 0,
              },
            ]
          : []),
      ];
      break;
    case 'motion':
      params.searches = [
        {
          q: query,
          collection: 'motiongraphics',
          query_by: 'name, keywords',
          per_page: 12,
          min_len_1typo: 6,
          num_typos: 1,
        },
        {
          q: query,
          collection: 'motiongraphicstyles',
          query_by: 'name,description',
          per_page: 12,
          min_len_1typo: 6,
          num_typos: 1,
        },
      ];
      break;
    default:
      params.searches = [
        {
          q: query,
          collection: 'tracks',
          query_by: 'name',
          per_page: 8,
        },
        {
          q: query,
          collection: 'artists',
          query_by: 'name',
          per_page: 6,
          min_len_1typo: 6,
          num_typos: 1,
        },
        {
          q: query,
          collection: 'collections',
          query_by: 'name, keywords',
          per_page: 12,
          min_len_1typo: 6,
          num_typos: 1,
        },
        {
          q: query,
          collection: 'tracktags',
          query_by: 'name',
          per_page: 10,
          min_len_1typo: 6,
          num_typos: 1,
        },
        {
          q: query,
          collection: 'trackstyles',
          query_by: 'name',
          per_page: 12,
          min_len_1typo: 6,
          num_typos: 1,
        },
        {
          q: query,
          collection: 'trackqualifiedkeywords',
          query_by: 'name',
          per_page: 10,
          min_len_1typo: 6,
          num_typos: 1,
        },
        ...(query.length > 2
          ? [
              {
                q: query,
                collection: 'searchredirects',
                query_by: 'name',
                per_page: 10,
                num_typos: 0,
              },
            ]
          : []),
      ];
      break;
  }

  // POST to /api/translate/search

  const res = await fetch(
    `https://${process.env.NEXT_PUBLIC_TYPESENSE_URL}/multi_search`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-TYPESENSE-API-KEY': process.env
          .NEXT_PUBLIC_TYPESENSE_API_KEY as string,
      },
      body: JSON.stringify(params),
    },
  );

  const search = (await res.json()) as MultiSearchResponse<
    GenericSearchResult[]
  >;

  // Remove empty results.
  const clearedList = search?.results?.filter(
    (result) => result.hits?.length && result.hits?.length > 0,
  );

  const redirects = clearedList.filter((item) => {
    if (item.request_params.collection_name === 'searchredirects') {
      return true;
    }

    return false;
  });

  const mergedCollections = mergeCollectionHits([...clearedList]);

  const redirect = redirects[0];

  const redirectHits = redirect?.hits ? redirect?.hits[0] : undefined;

  const isSfx = redirectHits?.document?.type === 'sfx';

  // Define collection priority for when scores are equal.
  // Higher number = higher priority.
  const collectionsValue = {
    'popular music searches': 1,
    'popular sfx searches': !isSfx ? 1 : 0,
    playlists: 2,
    'sfx collections': 3,
    artists: 4,
    tracks: 5,
  };

  const sortedCollections = mergedCollections.sort((a, b) => {
    const aCollection = a.request_params?.collection_name?.toLowerCase() as
      | keyof typeof collectionsValue
      | undefined;

    const bCollection = b.request_params?.collection_name?.toLowerCase() as
      | keyof typeof collectionsValue
      | undefined;

    if (!aCollection || !bCollection) {
      return 0;
    }

    const aCollectionValue = collectionsValue[aCollection];
    const bCollectionValue = collectionsValue[bCollection];

    return aCollectionValue - bCollectionValue;
  });

  // Typesense seems to ignore hyphens in scores.
  // e.g. "drum and bass" and "drum-and-bass" will have the same score.
  // We need to check all results for an exact match.
  const exactMatch = redirect?.hits
    ? redirect.hits.filter((item) => {
        if (item.document.name?.toLowerCase() === query.toLowerCase()) {
          return true;
        }

        return false;
      })[0]
    : false;

  const fuzzyMatch = redirect?.hits
    ? getFuzzyMatch(redirect.hits, query)
    : false;

  // Return fuzzy match if no exact match is found
  const matchedRedirect = exactMatch || fuzzyMatch;

  return [sortedCollections, matchedRedirect ? redirects : []];
};
