import { zodResolver } from '@hookform/resolvers/zod';
import { GoogleLogin } from '@react-oauth/google';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocalStorage } from 'react-use';
import { z } from 'zod';

import {
  googleSignIn,
  googleSignInActions,
} from '@/actions/authenticationAction';
import { getContentTypes, getVibeTypes } from '@/actions/dataCaptureActions';
import api from '@/api/legacyApi';
import { setAuthToken } from '@/auth';
import Error from '@/components/Form/Error';
import Form from '@/components/Form/Form';
import Input from '@/components/Form/Input';
import Label from '@/components/Form/Label';
import ToolTip from '@/components/Form/Tooltip';
import ArrowRight from '@/components/Icons/ArrowRight';
import RecaptchaBranding from '@/components/RecaptchaBranding';
import { useScreenContext } from '@/components/SteppedDialog/context/ScreenContext';
import { T } from '@/components/T';
import { Button, Loading } from '@/components/ui/button';
import { getUniversalCookie } from '@/cookies';
import { useHypertune } from '@/generated/hypertune.react';
import useRecaptcha, { RecaptchaVersion } from '@/hooks/useRecaptcha';
import { useSettings } from '@/hooks/useSettings';
import { useTranslate } from '@/hooks/useTranslate';
import { UserStateContext } from '@/providers/UserStateProvider';
import { getReferralCode, validateReferralCode } from '@/utils/helpers';
import optionallySkipRecaptcha from '@/utils/optionallySkipRecaptcha';

import { AccountType, CreateAccountModalScreenName } from './types';

import type { ContentType, VibeType } from './types';
import type { CredentialResponse } from '@react-oauth/google';
import type { SubmitHandler } from 'react-hook-form';

export interface AgreeCheckboxProps {
  agree: boolean;
  setAgree: (agree: boolean) => void;
  agreeError: boolean;
  setAgreeError: (agreeError: boolean) => void;
}

export const AgreeCheckbox = ({
  agree,
  setAgree,
  agreeError,
  setAgreeError,
}: AgreeCheckboxProps) => {
  const { t } = useTranslate();

  return (
    <div className="ub-modal-toggle-options-wrapper">
      <input
        id="agreement-check"
        type="checkbox"
        checked={agree}
        onChange={() => {
          if (!agree) {
            setAgree(true);
            setAgreeError(false);
          } else {
            setAgree(false);
          }
        }}
      />
      <div className={`ub-modal-toggle-options ${agreeError && 'error'}`}>
        <svg viewBox="0 0 20 20">
          <path
            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
            style={{ stroke: 'white', fill: 'white' }}
          />
        </svg>
        <label
          htmlFor="agreement-check"
          className={`ub-modal-toggle-options-text ub-modal-text checkbox-text ${
            agreeError ? ' error' : ''
          }`}
        >
          {t('I agree to the Uppbeat')}{' '}
          <a href="/privacy-and-cookie-policy" target="_blank" rel="noreferrer">
            {t('Privacy Policy')}
          </a>
          ,{' '}
          <a href="/user-agreement" target="_blank" rel="noreferrer">
            {' '}
            {t('User Agreement & Music Usage Policy')}
          </a>
          ,{' '}
          {t(
            'and am happy to receive occasional promotions and brand updates via email.',
          )}
        </label>
      </div>
    </div>
  );
};

// Define the Zod schema using the TypeScript enum
const schema = z.object({
  accountType: z.nativeEnum(AccountType),
  fullName: z.string().min(1, { message: 'Full name is required' }),
  emailAddress: z
    .string()
    .email({ message: 'Invalid email address' })
    .min(1, { message: 'Email is required' }),
  password: z
    .string()
    .min(8, { message: 'Password must be at least 8 characters long' })
    .max(30, { message: 'Password must be at most 30 characters long' })
    .regex(/[a-zA-Z]/, { message: 'Password must contain at least one letter' })
    .regex(/\d/, { message: 'Password must contain at least one number' }),
});

// Infer the type of the form data from the schema
type FormData = z.infer<typeof schema>;

interface CreateAccountFormProps {
  accountType: AccountType;
}

const CreateAccountForm = ({ accountType }: CreateAccountFormProps) => {
  const [agree, setAgree] = useState(false);
  const [agreeError, setAgreeError] = useState(false);
  const hypertune = useHypertune();

  const [error, setError] = useState<string | null>(null);
  const { recaptchaVersion, setRecaptchaVersion, recaptchaToken } =
    useRecaptcha('signup');

  const [googleLoading, setGoogleLoading] = useState(false);
  const [googleLoginFailure, setGoogleLoginFailure] = useState('');

  const googleLoginErrorMessage = googleLoginFailure;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      accountType,
    },
  });

  const { t } = useTranslate();
  const { setScreen, previousScreen } = useScreenContext();
  const { setUserState, userState } = useContext(UserStateContext);
  const { invalidateSettings } = useSettings();
  const [, setContentTypes] = useLocalStorage<ContentType[]>(
    'contentTypes',
    [],
  );

  const [, setVibeTypes] = useLocalStorage<VibeType[]>('vibeTypes', []);

  const handleErrorResponse = (registerError: RegisterErrorResponse) => {
    if (
      registerError.status === 'error' &&
      [10, 11, 12].includes(registerError.code)
    ) {
      setRecaptchaVersion(RecaptchaVersion.V2);
    }

    setError(registerError.message);
  };

  function conditionallyAppendFormData(
    name: string,
    data: string | null,
    formData: globalThis.FormData,
  ) {
    if (data) {
      formData.append(name, data);
    }

    return formData;
  }

  const getJsonFromFormData = (formData: globalThis.FormData): string => {
    const object: { [key: string]: unknown } = {};

    formData.forEach((value, key) => {
      object[key] = value;
    });

    return JSON.stringify(object);
  };

  interface LoginErrorResponse {
    status: 'error';
    code: number;
    message: string;
  }

  interface LoginSuccessResponse {
    auth_token: string;
    code: number;
    country: string;
    currency: { id: number; symbol_left: string; code: string };
    email: string;
    emailHash: string;
    first_name: string;
    hasAccountArtist: boolean;
    hasAccountCustomer: boolean;
    isAdmin: boolean;
    isGoogleUser: boolean;
    isTrusted: boolean;
    last_name: string;
    message: string;
    profile_picture: string | null;
    status: 'success';
    token: string;
    trustedName: string;
    user_id: string;
  }

  type LoginResponse = LoginErrorResponse | LoginSuccessResponse;

  const makeLoginAttempt = async (
    signupData: FormData,
    signupResponse: RegisterSuccessResponse,
  ) => {
    const formData = new FormData();

    formData.append('_username', signupData.emailAddress);
    formData.append('_password', signupData.password);
    formData.append('recaptcha_version', recaptchaVersion);
    formData.append('recaptcha_token', recaptchaToken ?? '');
    formData.append('create_legacy', 'false');
    formData.append('user_id', signupResponse.user_id);
    formData.append('one_time_token', signupResponse.one_time_token);

    try {
      const res = await api<LoginResponse>('authentication/login', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          ...optionallySkipRecaptcha(),
        },
        body: getJsonFromFormData(formData),
      });

      if (res.status === 'error') {
        console.warn(res.message);

        return;
      }

      setContentTypes(signupResponse.contents);
      setVibeTypes(signupResponse.vibes);

      sessionStorage.setItem('signUpTime', new Date().getTime().toString());
      setUserState('hasLoggedIn', 'true');

      localStorage.setItem(
        'is_google_user',
        signupResponse.isGoogleUser ? '1' : '0',
      );
      localStorage.setItem('quick_login', signupResponse.email);

      setAuthToken(res.auth_token);

      invalidateSettings();

      if (accountType === AccountType.Premium) {
        setScreen(CreateAccountModalScreenName.CheckoutPremium);

        return;
      }

      if (accountType === AccountType.Business) {
        setScreen(CreateAccountModalScreenName.CheckoutBusiness);

        return;
      }

      setScreen(CreateAccountModalScreenName.WhatsYourThemeFree);
    } catch (err) {
      console.warn(err);
    }
  };

  interface RegisterErrorResponse {
    status: 'error';
    code: number;
    message: string;
  }

  interface RegisterSuccessResponse {
    status: 'success';
    user_id: string;
    one_time_token: string;
    contents: ContentType[];
    vibes: VibeType[];
    auth_token: string;
    isGoogleUser: boolean;
    email: string;
  }

  type RegisterResponse = RegisterErrorResponse | RegisterSuccessResponse;

  const makeRegisterAttempt = async (signupData: FormData) => {
    const chosenTheme = localStorage.getItem('theme') === 'dark' ? 1 : 0;

    let formData = new FormData();

    formData.append('first_last_name', signupData.fullName);
    formData.append('email', signupData.emailAddress);
    formData.append('password', signupData.password);
    formData.append('suppress_welcome', '0'); // Not used in UB
    formData.append('recaptcha_version', recaptchaVersion);
    formData.append('recaptcha_token', recaptchaToken ?? '');
    formData.append('rcm', validateReferralCode());
    formData.append(
      'partnerstack_key',
      getUniversalCookie('ps_partner_key') ?? '',
    );
    formData.append(
      'partner_stack_referrer_id',
      getUniversalCookie('ps_xid') ?? '',
    );

    // Force fail recaptcha if value set in localstorage
    if (localStorage.getItem('force_fail_recaptcha')) {
      formData.append('force_fail_recaptcha', '1');
    }

    formData = conditionallyAppendFormData(
      'partner_code',
      localStorage.getItem('partner_code'),
      formData,
    );
    formData = conditionallyAppendFormData(
      'rt_code',
      localStorage.getItem('rt_code'),
      formData,
    );
    formData = conditionallyAppendFormData(
      'affiliate_code',
      localStorage.getItem('as_code'),
      formData,
    );
    formData = conditionallyAppendFormData(
      'user_referral_code',
      getReferralCode(),
      formData,
    );
    formData = conditionallyAppendFormData(
      'share_name_referrer',
      sessionStorage.getItem('name_consent'),
      formData,
    );
    formData = conditionallyAppendFormData(
      'ab_seed',
      localStorage.getItem('ab_seed'),
      formData,
    );
    formData = conditionallyAppendFormData(
      'ab_timestamp',
      localStorage.getItem('ab_ts'),
      formData,
    );
    formData = conditionallyAppendFormData(
      'impact_clickid',
      localStorage.getItem('irclickid'),
      formData,
    );

    formData = conditionallyAppendFormData(
      'theme_preference',
      chosenTheme.toString(),
      formData,
    );

    const res = await api<RegisterResponse>(
      'user/register',
      {
        method: 'POST',
        body: formData,
        headers: optionallySkipRecaptcha(),
      },
      {
        defaultContentType: false,
      },
    );

    return res;
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (accountType === AccountType.Business && !agree) {
      setAgreeError(true);

      return;
    }

    setError(null);
    const res = await makeRegisterAttempt(data);

    if (res.status === 'error') {
      handleErrorResponse(res);

      return;
    }

    await makeLoginAttempt(data, res);
  };

  const handleGoogleSignInSuccess = async (
    response: CredentialResponse,
    nextScreen: CreateAccountModalScreenName,
  ) => {
    const { clientId, credential, select_by } = response;

    setGoogleLoading(true);

    const payload = {
      client_id: clientId,
      credential,
      select_by,
      access_token: 'accessToken',
      affiliate_code: localStorage.getItem('as_code'),
      partner_code: localStorage.getItem('partner_code'),
      user_referral_code: getReferralCode(),
      rcm: validateReferralCode(),
      share_name_referrer: sessionStorage.getItem('name_consent'),
      ab_seed: userState.abSeed,
      ab_timestamp: userState.abTimestamp,
      rt_code: localStorage.getItem('rt_code'),
      email: 'email',
      impact_clickid: localStorage.getItem('irclickid'),
      partnerstack_key: getUniversalCookie('ps_partner_key'),
      partner_stack_referrer_id: getUniversalCookie('ps_xid'),
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    await googleSignIn(payload).then((response) => {
      if (response.status === 'success') {
        const { contents, vibes, email } = response;

        const loginPayload = {
          credential,
          email,
        };

        fetch(
          `${process.env.NEXT_PUBLIC_API}authentication/googleloginsimple`,
          {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(loginPayload),
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
          .then((res) => {
            if (res.ok) {
              getContentTypes(contents);
              getVibeTypes(vibes);

              googleSignInActions(response, setUserState);

              invalidateSettings();

              setGoogleLoading(false);

              setScreen(nextScreen);

              if (response.isNewUser) {
                hypertune.register({
                  args: {
                    type: 'Google',
                  },
                });
              }
            } else {
              setGoogleLoading(false);
              setGoogleLoginFailure('Something went wrong, please try again.');
            }
          })
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          .catch((err) => err);
      } else {
        setGoogleLoading(false);
        setGoogleLoginFailure(
          (response.message ?? 'Something went wrong') as string,
        );
      }
    });
  };

  const isLoading = isSubmitting || googleLoading;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <input
        type="hidden"
        {...register('accountType', {
          value: accountType,
        })}
      />

      <div>
        <Label srOnly>
          <T>Full Name</T>
        </Label>
        <Input
          placeholder="Full Name"
          register={register}
          name="fullName"
          hasError={!!errors.fullName}
        />
        {!!errors.fullName?.message && (
          <Error>
            <T>{errors.fullName.message}</T>
          </Error>
        )}
      </div>

      <div>
        <Label srOnly>
          <T>Email Address</T>
        </Label>
        <Input
          type="email"
          placeholder="Email Address"
          register={register}
          name="emailAddress"
          hasError={!!errors.emailAddress}
        />
        {!!errors.emailAddress?.message && (
          <Error>
            <T>{errors.emailAddress.message}</T>
          </Error>
        )}
      </div>

      <div>
        <Label srOnly>
          <T>Password</T>
        </Label>
        <Input
          type="password"
          placeholder="Password"
          register={register}
          name="password"
          hasError={!!errors.password}
        />

        <ToolTip
          trigger={
            errors.password?.message ? (
              <Error>{errors.password.message}</Error>
            ) : (
              t('Password Requirements')
            )
          }
          hasError={!!errors.password}
        >
          <T>
            Must be between 8 and 30 characters long, with at least one letter
            and one number.
          </T>
        </ToolTip>
      </div>

      {recaptchaVersion === RecaptchaVersion.V2 && <div id="recaptcha-v2" />}

      {!!error && (
        <Error className="text-center text-pretty">
          <T>{error}</T>
        </Error>
      )}

      {accountType === AccountType.Business && (
        <>
          <AgreeCheckbox
            agree={agree}
            setAgree={setAgree}
            agreeError={agreeError}
            setAgreeError={setAgreeError}
          />
          <div className="ub-modal-google-wrapper">
            <GoogleLogin
              onSuccess={(data) => {
                if (agree) {
                  void handleGoogleSignInSuccess(
                    data,
                    CreateAccountModalScreenName.CheckoutBusiness,
                  );
                } else {
                  setAgreeError(true);
                }
              }}
              theme="filled_blue"
              shape="pill"
              text="signup_with"
              size="large"
              type="standard"
              width="292px"
              itp_support={false}
            />
          </div>
        </>
      )}

      {!!googleLoginErrorMessage && (
        <div className="alert-general-error login-error mb-4">
          <span>{googleLoginErrorMessage}</span>
        </div>
      )}

      <div className="flex justify-center my-4 gap-2 items-center">
        {!!previousScreen && (
          <Button
            variant="secondary-modal"
            borderRadius="full"
            disabled={isLoading}
            onClick={() => {
              setScreen(previousScreen);
            }}
            className="flex-shrink-0"
            type="button"
            size="icon"
          >
            <ArrowRight className="rotate-180" width={14} />
          </Button>
        )}
        <Button
          variant="uppbeat"
          disabled={isLoading}
          type="submit"
          className="w-full"
        >
          <Loading visible={isLoading} />
          Sign up with email
        </Button>
      </div>
      <RecaptchaBranding />
    </Form>
  );
};

export default CreateAccountForm;
