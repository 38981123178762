import React from 'react';
import { connect } from 'react-redux';
import { addModal } from '../../actions/authenticationAction';
import A from '../../components/A';
import FacebookSVG from '../../styles/svg-components/Facebook';
import InstagramSVG from '../../styles/svg-components/Instagram';
import TwitterSVG from '../../styles/svg-components/Twitter';
import { drip } from '../../utils/drip';
import { T } from '../T';
import UBTrustBoxContainer from '../UBTrustPilot';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      artistCount: false,
      trackCount: false,
    };

    this.handleSocialClick = this.handleSocialClick.bind(this);
  }

  handleSocialClick(platform) {
    drip('Social Channel Visited', platform);
  }

  render() {
    return (
      <footer className="footer">
        <div className="footer-top">
          <UBTrustBoxContainer />
          <p className="footer-tag-line">
            Freedom to <span className="text-uppbeat">create</span> 🙌
          </p>
        </div>
        <div className="footer-bottom">
          <div className="footer-box">
            <A href="/pricing" aria-label="Uppbeat Premium">
              <svg
                className="footer-logo"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 14.78 4.94"
              >
                <g>
                  <path
                    className="colour-a"
                    d="M2.42,1.46c0-0.25-0.14-0.39-0.35-0.34c0,0-0.01,0-0.01,0c0,0,0,0,0,0S1.67,1.17,1.67,1.65v1.07
                                        c0,0.18-0.14,0.35-0.31,0.39L1.31,3.12C1.14,3.16,1.01,3.04,1.01,2.86V2.21c0.03,0.01,0.07,0.01,0.11,0
                                        c0.21-0.04,0.36-0.25,0.36-0.49c0-0.25-0.14-0.39-0.35-0.34c0,0-0.01,0-0.01,0c0,0,0,0,0,0S0.73,1.43,0.73,1.91v0.01v0.99
                                        c0,0.34,0.26,0.54,0.58,0.48l0.06-0.01c0.32-0.06,0.58-0.37,0.58-0.71V1.95c0.03,0.01,0.07,0.01,0.11,0
                                        C2.27,1.91,2.42,1.7,2.42,1.46 M1.23,1.77c0,0.09-0.05,0.17-0.12,0.18C1.03,1.97,0.98,1.91,0.98,1.82c0-0.09,0.05-0.16,0.12-0.18
                                        C1.18,1.63,1.23,1.68,1.23,1.77 M2.05,1.69C1.98,1.71,1.93,1.65,1.93,1.56c0-0.09,0.05-0.16,0.12-0.18
                                        c0.07-0.02,0.12,0.04,0.12,0.13C2.17,1.6,2.13,1.67,2.05,1.69"
                  />
                  <path
                    className="colour-a"
                    d="M1.99,3.31C1.94,3.29,1.88,3.3,1.85,3.36C1.84,3.36,1.72,3.58,1.44,3.6c-0.06,0-0.1,0.05-0.1,0.11
                                        c0,0.05,0.04,0.1,0.1,0.1c0,0,0.01,0,0.01,0c0.4-0.02,0.58-0.34,0.58-0.35C2.06,3.4,2.04,3.34,1.99,3.31"
                  />
                  <path
                    className="colour-a"
                    d="M4.57,1.33c0,0-0.01-0.01-0.01-0.01C4.2,1.12,3.89,1.3,3.87,1.31C3.82,1.34,3.8,1.4,3.83,1.45
                                        C3.86,1.5,3.93,1.52,3.98,1.49C3.99,1.49,4.21,1.37,4.45,1.5C4.5,1.53,4.57,1.51,4.59,1.46C4.62,1.42,4.61,1.36,4.57,1.33"
                  />
                  <path
                    className="colour-a"
                    d="M8.89,2.11c-0.01,0-0.25,0.09-0.47-0.08C8.37,2,8.31,2,8.27,2.05c-0.03,0.04-0.03,0.1,0.01,0.14
                                        c0,0,0.01,0.01,0.01,0.01c0.32,0.24,0.66,0.11,0.67,0.11c0.05-0.02,0.08-0.08,0.06-0.14C9,2.12,8.94,2.09,8.89,2.11"
                  />
                  <path
                    className="colour-a"
                    d="M2.81,1.95C2.66,1.98,2.54,2.07,2.49,2.17V2.08c0-0.03-0.02-0.04-0.05-0.04L2.25,2.08
                                        c-0.02,0-0.03,0.02-0.03,0.04v1.43c0,0.03,0.02,0.04,0.05,0.04l0.2-0.04c0.02,0,0.03-0.02,0.03-0.04V3.02
                                        c0.05,0.06,0.16,0.1,0.3,0.07C3.1,3.03,3.29,2.75,3.29,2.42C3.29,2.09,3.12,1.89,2.81,1.95 M2.76,2.83C2.62,2.86,2.5,2.77,2.5,2.58
                                        c0-0.19,0.12-0.33,0.26-0.36C2.9,2.19,3.02,2.28,3.02,2.47C3.02,2.67,2.9,2.8,2.76,2.83"
                  />
                  <path
                    className="colour-a"
                    d="M4.11,1.69C3.95,1.72,3.83,1.81,3.79,1.91V1.82c0-0.03-0.02-0.04-0.05-0.04L3.55,1.82
                                        c-0.02,0-0.03,0.02-0.03,0.04v1.43c0,0.03,0.02,0.04,0.05,0.04l0.18-0.04C3.77,3.29,3.8,3.26,3.8,3.23V2.76
                                        c0.05,0.06,0.16,0.1,0.3,0.07c0.29-0.06,0.49-0.34,0.49-0.67C4.59,1.83,4.41,1.62,4.11,1.69 M4.05,2.57
                                        C3.91,2.6,3.79,2.51,3.79,2.32c0-0.19,0.12-0.33,0.26-0.36C4.2,1.93,4.31,2.02,4.31,2.21C4.31,2.41,4.2,2.54,4.05,2.57"
                  />
                  <path
                    className="colour-a"
                    d="M5.4,1.42C5.24,1.45,5.13,1.55,5.08,1.63V1.05c0-0.03-0.02-0.04-0.05-0.04l-0.2,0.04
                                        c-0.02,0-0.03,0.02-0.03,0.04v1.52c0,0.03,0.02,0.04,0.05,0.04l0.19-0.04c0.02,0,0.03-0.02,0.03-0.04v-0.1
                                        c0.05,0.08,0.16,0.13,0.31,0.1c0.3-0.06,0.49-0.34,0.49-0.67C5.88,1.57,5.7,1.36,5.4,1.42 M5.34,2.31C5.2,2.34,5.08,2.25,5.08,2.06
                                        c0-0.2,0.12-0.33,0.26-0.36C5.48,1.67,5.6,1.75,5.6,1.95C5.6,2.15,5.48,2.28,5.34,2.31"
                  />
                  <path
                    className="colour-a"
                    d="M6.53,1.19C6.27,1.24,6.02,1.52,6.02,1.86c0,0.37,0.25,0.53,0.54,0.47c0.23-0.05,0.39-0.21,0.46-0.39
                                        c0.01-0.02-0.01-0.05-0.03-0.05L6.83,1.87c-0.02,0-0.03,0.01-0.04,0.02C6.75,1.99,6.68,2.06,6.56,2.08
                                        C6.42,2.11,6.31,2.03,6.3,1.89L7,1.75c0.02,0,0.04-0.02,0.04-0.05c0-0.02,0-0.03,0-0.05C7.05,1.3,6.85,1.12,6.53,1.19 M6.31,1.69
                                        c0.01-0.1,0.09-0.23,0.23-0.26C6.7,1.39,6.77,1.49,6.77,1.59L6.31,1.69z"
                  />
                  <path
                    className="colour-a"
                    d="M8.12,1.82V1.28c0-0.22-0.12-0.39-0.45-0.32c-0.25,0.05-0.4,0.24-0.44,0.4C7.23,1.38,7.25,1.41,7.27,1.41
                                        l0.17,0c0.02,0,0.04-0.01,0.04-0.03C7.5,1.29,7.57,1.22,7.67,1.19C7.79,1.17,7.85,1.22,7.85,1.3c0,0.04-0.02,0.07-0.08,0.09
                                        L7.52,1.48c-0.17,0.06-0.31,0.2-0.31,0.39c0,0.17,0.14,0.3,0.36,0.26C7.72,2.1,7.82,2.01,7.87,1.91c0,0.03,0,0.06,0,0.08
                                        c0,0.02,0.02,0.04,0.05,0.04L8.1,1.99c0.02,0,0.03-0.02,0.03-0.04C8.13,1.91,8.12,1.86,8.12,1.82 M7.85,1.6
                                        c0,0.19-0.11,0.27-0.23,0.3C7.53,1.92,7.49,1.87,7.49,1.8c0-0.08,0.06-0.14,0.13-0.16l0.23-0.08V1.6z"
                  />
                  <path
                    className="colour-a"
                    d="M8.33,1.12l0.14-0.03V1.6c0,0.21,0.13,0.31,0.33,0.27c0.06-0.01,0.11-0.03,0.14-0.05
                                        C8.95,1.81,8.96,1.8,8.96,1.79V1.62c0-0.03-0.03-0.05-0.05-0.04c-0.01,0-0.02,0.01-0.04,0.01c-0.08,0.02-0.12-0.01-0.12-0.1V1.03
                                        L8.92,1c0.02,0,0.03-0.02,0.03-0.04V0.78c0-0.03-0.02-0.04-0.05-0.04L8.75,0.77V0.5c0-0.03-0.02-0.04-0.05-0.04L8.53,0.49
                                        c-0.02,0-0.03,0.02-0.03,0.04v0.12c0,0.1-0.05,0.18-0.16,0.2l-0.02,0c-0.02,0-0.03,0.02-0.03,0.04v0.18
                                        C8.28,1.11,8.3,1.12,8.33,1.12"
                  />
                </g>
                <g>
                  <path
                    className="colour-a"
                    d="M14.33,3.11l-0.53-0.05l-0.21-0.49c-0.01-0.02-0.04-0.02-0.04,0l-0.21,0.49l-0.53,0.05
                                        c-0.02,0-0.03,0.03-0.01,0.04l0.4,0.35l-0.11,0.52c0,0.02,0.02,0.04,0.04,0.03l0.46-0.27l0.46,0.27c0.02,0.01,0.04-0.01,0.04-0.03
                                        L13.95,3.5l0.4-0.35C14.36,3.13,14.35,3.11,14.33,3.11z"
                  />
                </g>
                <g>
                  <path
                    className="colour-b"
                    d="M5.33,3.26c0-0.01,0.01-0.03,0.03-0.03l0.35-0.01c0.18-0.01,0.34,0.14,0.35,0.32
                                        c0.01,0.18-0.14,0.34-0.32,0.35L5.55,3.9l0.01,0.35c0,0.01-0.01,0.03-0.03,0.03l-0.14,0c-0.02,0-0.03-0.01-0.03-0.03L5.33,3.26z
                                        M5.72,3.71c0.08,0,0.15-0.07,0.14-0.16c0-0.08-0.07-0.14-0.15-0.14l-0.18,0l0.01,0.3L5.72,3.71z"
                  />
                  <path
                    className="colour-b"
                    d="M6.27,3.24c0-0.01,0.01-0.03,0.03-0.03l0.43-0.01C6.9,3.19,7.05,3.33,7.06,3.5c0,0.14-0.08,0.25-0.21,0.31
                                        l0.22,0.37c0.01,0.02,0,0.04-0.02,0.04l-0.16,0c-0.01,0-0.02-0.01-0.02-0.01L6.65,3.83l-0.17,0l0.01,0.38
                                        c0,0.01-0.01,0.03-0.03,0.03l-0.14,0c-0.02,0-0.03-0.01-0.03-0.03L6.27,3.24z M6.72,3.67c0.08,0,0.15-0.07,0.14-0.16
                                        c0-0.08-0.07-0.14-0.15-0.14L6.47,3.38l0.01,0.3L6.72,3.67z"
                  />
                  <path
                    className="colour-b"
                    d="M7.31,3.2c0-0.01,0.01-0.03,0.03-0.03l0.61-0.02c0.02,0,0.03,0.01,0.03,0.03l0,0.12
                                        c0,0.01-0.01,0.03-0.03,0.03L7.51,3.35l0.01,0.25l0.37-0.01c0.01,0,0.03,0.01,0.03,0.03l0,0.12c0,0.02-0.01,0.03-0.03,0.03
                                        L7.52,3.77l0.01,0.26l0.44-0.01C7.99,4.02,8,4.04,8,4.05l0,0.12C8.01,4.19,8,4.2,7.98,4.2L7.37,4.22c-0.02,0-0.03-0.01-0.03-0.03
                                        L7.31,3.2z"
                  />
                  <path
                    className="colour-b"
                    d="M8.35,3.15c0-0.01,0.01-0.02,0.03-0.02l0.03,0c0.01,0,0.02,0.01,0.03,0.01l0.35,0.7c0,0,0,0,0,0l0.31-0.72
                                        c0-0.01,0.01-0.02,0.03-0.02l0.02,0c0.02,0,0.03,0.01,0.03,0.02l0.21,1c0.01,0.02-0.01,0.03-0.03,0.04l-0.14,0
                                        c-0.01,0-0.03-0.01-0.03-0.02L9.08,3.58c0,0,0,0,0,0L8.83,4.17c0,0.01-0.01,0.02-0.02,0.02l-0.03,0c-0.01,0-0.02-0.01-0.03-0.02
                                        L8.47,3.6c0,0,0,0,0,0L8.39,4.17c0,0.01-0.01,0.02-0.03,0.02l-0.13,0C8.21,4.2,8.2,4.18,8.2,4.16L8.35,3.15z"
                  />
                  <path
                    className="colour-b"
                    d="M9.59,3.13c0-0.01,0.01-0.03,0.03-0.03l0.14,0c0.01,0,0.03,0.01,0.03,0.03l0.03,0.99
                                        c0,0.01-0.01,0.03-0.03,0.03l-0.14,0c-0.01,0-0.03-0.01-0.03-0.03L9.59,3.13z"
                  />
                  <path
                    className="colour-b"
                    d="M10.09,3.12c0-0.01,0.01-0.03,0.03-0.03l0.14,0c0.02,0,0.03,0.01,0.03,0.03l0.02,0.62
                                        c0,0.13,0.09,0.21,0.22,0.21s0.22-0.09,0.21-0.22L10.72,3.1c0-0.01,0.01-0.03,0.03-0.03l0.14,0c0.01,0,0.03,0.01,0.03,0.03
                                        l0.02,0.63c0.01,0.23-0.17,0.41-0.41,0.41c-0.23,0.01-0.42-0.16-0.43-0.39L10.09,3.12z"
                  />
                  <path
                    className="colour-b"
                    d="M11.33,3.06c0-0.01,0.01-0.02,0.03-0.02l0.02,0c0.01,0,0.02,0,0.03,0.01l0.35,0.7c0,0,0,0,0,0l0.31-0.72
                                        c0-0.01,0.01-0.02,0.03-0.02l0.02,0c0.02,0,0.03,0.01,0.03,0.02l0.21,1c0,0.02-0.01,0.03-0.03,0.03l-0.14,0
                                        c-0.01,0-0.03-0.01-0.03-0.02l-0.11-0.56c0,0,0,0,0,0l-0.24,0.59c0,0.01-0.01,0.02-0.02,0.02l-0.03,0c-0.01,0-0.02-0.01-0.03-0.02
                                        l-0.28-0.58c0,0,0,0,0,0l-0.07,0.57c0,0.01-0.01,0.02-0.03,0.02l-0.13,0c-0.02,0-0.03-0.01-0.03-0.03L11.33,3.06z"
                  />
                </g>
              </svg>
            </A>
            <p className="footer-text">
              The essential music & sound effects resource for creators
              worldwide. Home to the world's hottest artists. Safelist your
              channels. Better than any royalty-free or stock music.
            </p>
            {!this.props.userAuth.isPremium && (
              <A href="/pricing" className="footer-link">
                <T keyName={'footer.viewPricing'}>View Pricing</T>
              </A>
            )}
          </div>
          <div className="footer-box">
            <A href="/pricing" aria-label="Uppbeat Business">
              <svg
                className="footer-logo"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 108.04 36.11"
              >
                <path
                  className="colour-a"
                  d="M15.09,11.05c0-1.91-1.06-3-2.66-2.59h-.08s-3,.38-3,4v8.15a3.19,3.19,0,0,1-2.36,3l-.38.07a1.84,1.84,0,0,1-2.29-2V16.76a1.7,1.7,0,0,0,.84,0A3.67,3.67,0,0,0,7.94,13c0-1.91-1.07-3-2.66-2.59H5.2s-3,.38-3,4v7.61a3.55,3.55,0,0,0,4.42,3.66l.45-.08a5.63,5.63,0,0,0,4.42-5.4V14.78a1.66,1.66,0,0,0,.83,0,3.67,3.67,0,0,0,2.74-3.73M6,13.41a1.3,1.3,0,0,1-.92,1.37c-.61.15-1-.31-1-1a1.35,1.35,0,0,1,.92-1.37c.61-.08,1,.3,1,1m6.24-.61c-.54.15-.92-.31-.92-1a1.35,1.35,0,0,1,.92-1.37c.53-.15.91.3.91,1a1.27,1.27,0,0,1-.91,1.37"
                />
                <path
                  className="colour-a"
                  d="M11.82,25.13a.79.79,0,0,0-1.06.38,4.34,4.34,0,0,1-3.13,1.82.79.79,0,0,0-.76.84.77.77,0,0,0,.76.76h.08a5.37,5.37,0,0,0,4.42-2.66.85.85,0,0,0-.31-1.14"
                />
                <path
                  className="colour-a"
                  d="M31.46,10.06,31.38,10a5.44,5.44,0,0,0-5.25-.07A.77.77,0,0,0,25.82,11a.88.88,0,0,0,1.15.31,4.07,4.07,0,0,1,3.57.07.75.75,0,0,0,1.07-.3.72.72,0,0,0-.15-1"
                />
                <path
                  className="colour-a"
                  d="M64.34,16a4,4,0,0,1-3.58-.6.83.83,0,0,0-1.14.15.81.81,0,0,0,.07,1.06l.08.08a5.7,5.7,0,0,0,5.1.84.86.86,0,0,0,.46-1.07.8.8,0,0,0-1-.46"
                />
                <path
                  className="colour-a"
                  d="M18.06,14.78a3.45,3.45,0,0,0-2.43,1.67v-.68c0-.23-.16-.31-.38-.31l-1.45.31c-.15,0-.23.15-.23.3V27c0,.23.15.31.38.31L15.47,27c.16,0,.23-.15.23-.3V22.92a2.33,2.33,0,0,0,2.29.53,4.91,4.91,0,0,0,3.72-5.1c0-2.51-1.29-4-3.65-3.57m-.38,6.69c-1.06.23-2-.45-2-1.9a2.71,2.71,0,0,1,2-2.74c1.07-.23,2,.46,2,1.9a2.62,2.62,0,0,1-2,2.74"
                />
                <path
                  className="colour-a"
                  d="M28,12.8a3.19,3.19,0,0,0-2.44,1.67v-.68c0-.23-.15-.31-.38-.31l-1.45.31c-.15,0-.22.15-.22.3V25c0,.22.15.3.38.3l1.37-.3a.5.5,0,0,0,.38-.46V20.94a2.31,2.31,0,0,0,2.28.53,5,5,0,0,0,3.73-5.1c0-2.51-1.37-4.11-3.65-3.57m-.46,6.7c-1.07.22-2-.46-2-1.91a2.7,2.7,0,0,1,2-2.74c1.14-.23,2,.46,2,1.91a2.57,2.57,0,0,1-2,2.74"
                />
                <path
                  className="colour-a"
                  d="M37.77,10.74a3.74,3.74,0,0,0-2.43,1.6V7.93c0-.23-.15-.31-.38-.31l-1.52.31c-.16,0-.23.15-.23.3V19.8c0,.23.15.3.38.3L35,19.8c.16,0,.23-.15.23-.3v-.77a2.16,2.16,0,0,0,2.36.77,4.92,4.92,0,0,0,3.73-5.1c.08-2.52-1.29-4.11-3.58-3.66m-.45,6.78c-1.07.22-2-.46-2-1.91a2.64,2.64,0,0,1,2-2.74c1.06-.22,2,.38,2,1.91a2.65,2.65,0,0,1-2,2.74"
                />
                <path
                  className="colour-a"
                  d="M46.37,9a5.24,5.24,0,0,0-3.88,5.1c0,2.82,1.91,4,4.11,3.58a4.83,4.83,0,0,0,3.5-3,.29.29,0,0,0-.22-.38l-1.22-.15a.4.4,0,0,0-.31.15,2.17,2.17,0,0,1-1.75,1.45,1.54,1.54,0,0,1-2-1.45L50,13.25a.35.35,0,0,0,.31-.38v-.38c.07-2.66-1.45-4-3.89-3.5M44.7,12.8a2.33,2.33,0,0,1,1.75-2A1.25,1.25,0,0,1,48.2,12Z"
                />
                <path
                  className="colour-a"
                  d="M58.48,13.79V9.68c0-1.68-.92-3-3.43-2.44a4.33,4.33,0,0,0-3.35,3c0,.15.15.38.31.38H53.3c.15,0,.3-.08.3-.23A2,2,0,0,1,55.05,9c.91-.15,1.37.23,1.37.84,0,.3-.15.53-.61.68l-1.9.69a3.26,3.26,0,0,0-2.36,3,2.1,2.1,0,0,0,2.74,2,3.19,3.19,0,0,0,2.28-1.68v.61a.36.36,0,0,0,.38.31l1.37-.31c.16,0,.23-.15.23-.3,0-.31-.07-.69-.07-1m-2.06-1.68a2.15,2.15,0,0,1-1.75,2.29.72.72,0,0,1-1-.77,1.3,1.3,0,0,1,1-1.21l1.75-.61Z"
                />
                <path
                  className="colour-a"
                  d="M60.07,8.46l1.07-.23v3.88a1.9,1.9,0,0,0,2.51,2.06,3,3,0,0,0,1.07-.38c.07-.08.15-.16.15-.23v-1.3c0-.22-.23-.38-.38-.3s-.15.08-.31.08c-.6.15-.91-.08-.91-.76V7.77l1.29-.22c.16,0,.23-.16.23-.31V5.87c0-.23-.15-.3-.38-.3l-1.14.23V3.74c0-.23-.15-.3-.38-.3l-1.29.22c-.16,0-.23.16-.23.31v.91A1.42,1.42,0,0,1,60.15,6.4H60c-.15,0-.23.16-.23.31V8.08c-.08.3.08.38.3.38"
                />
                <path
                  className="colour-c"
                  d="M100.87,19.12l-7.13.27L94.18,31l7.12-.27Zm-3.56,9.51-.74,0,0-1.35.74,0Zm-.1-2.71-.74,0,0-1.34.74,0Zm-.1-2.7-.74,0-.05-1.35.74,0Zm2.44,5.33-.74,0,0-1.34.74,0Zm-.1-2.72-.74,0,0-1.35.73,0Zm-.11-2.69-.73,0-.06-1.35.74,0Z"
                />
                <path
                  className="colour-c"
                  d="M106.14,29l-.21-5.37-4.28.16.26,6.83,2.83-.11A1.46,1.46,0,0,0,106.14,29Zm-1.68-.66-1.44.06,0-.72,1.43,0Zm-.07-1.8-1.43.05,0-.73,1.43-.06Z"
                />
                <path
                  className="colour-b"
                  d="M41.51,28.28a1.91,1.91,0,0,1,1.43,1.82,2.32,2.32,0,0,1-2.44,2.29l-2.78.08a.23.23,0,0,1-.23-.21l-.21-7.61a.22.22,0,0,1,.21-.23l2.63-.08a2.27,2.27,0,0,1,2.49,2.06,2,2,0,0,1-1.1,1.83Zm-1.41-.55A1,1,0,0,0,41,26.67a.93.93,0,0,0-1-1l-1.24,0,.05,2Zm.26,3.3a1,1,0,0,0,1-1.07,1,1,0,0,0-1-1l-1.41,0,.06,2Z"
                />
                <path
                  className="colour-b"
                  d="M44.63,24.44a.22.22,0,0,1,.21-.23l1.09,0a.23.23,0,0,1,.23.22l.13,4.79a1.67,1.67,0,0,0,3.34-.09l-.13-4.8a.2.2,0,0,1,.21-.22l1.09,0a.22.22,0,0,1,.23.21l.14,4.87a3.21,3.21,0,0,1-6.41.18Z"
                />
                <path
                  className="colour-b"
                  d="M53.11,31.26c-.12-.1-.16-.17-.07-.35l.4-.73a.25.25,0,0,1,.38-.08,2.82,2.82,0,0,0,1.76.66,1,1,0,0,0,1-.92c0-.58-.52-1-1.49-1.33s-2.2-1.07-2.23-2.43A2.29,2.29,0,0,1,55.4,23.8a4.15,4.15,0,0,1,2.32.7.3.3,0,0,1,.08.38l-.42.68c-.09.13-.26.23-.4.14a2.9,2.9,0,0,0-1.61-.59c-.66,0-1,.4-1,.83s.45.91,1.39,1.26c1.13.41,2.43,1.05,2.47,2.52a2.41,2.41,0,0,1-2.57,2.35A3.72,3.72,0,0,1,53.11,31.26Z"
                />
                <path
                  className="colour-b"
                  d="M59.86,24a.23.23,0,0,1,.21-.23l1.07,0a.23.23,0,0,1,.22.21l.22,7.62a.23.23,0,0,1-.21.22l-1.07,0a.22.22,0,0,1-.23-.21Z"
                />
                <path
                  className="colour-b"
                  d="M63.67,23.78a.23.23,0,0,1,.22-.22h.17a.29.29,0,0,1,.2.07l4.82,4.87h0L69,23.76a.22.22,0,0,1,.22-.23l1,0a.22.22,0,0,1,.23.21l.22,7.74a.23.23,0,0,1-.22.21h-.16a.26.26,0,0,1-.19-.07l-4.86-5.07h0l.14,4.95a.21.21,0,0,1-.21.22l-1,0a.22.22,0,0,1-.23-.2Z"
                />
                <path
                  className="colour-b"
                  d="M72.77,23.65a.22.22,0,0,1,.21-.24l4.67-.12a.21.21,0,0,1,.23.21l0,.94a.23.23,0,0,1-.21.23l-3.41.1.06,1.88,2.83-.08a.22.22,0,0,1,.23.21l0,.95a.22.22,0,0,1-.22.23l-2.83.09,0,2L77.85,30a.21.21,0,0,1,.22.21l0,.94a.23.23,0,0,1-.22.23l-4.67.12a.2.2,0,0,1-.22-.2Z"
                />
                <path
                  className="colour-b"
                  d="M79.54,30.51c-.12-.09-.16-.16-.07-.35l.4-.72a.26.26,0,0,1,.38-.09A2.72,2.72,0,0,0,82,30a1,1,0,0,0,1-.92c0-.58-.52-1-1.5-1.33s-2.19-1.07-2.23-2.42a2.3,2.3,0,0,1,2.53-2.28,4,4,0,0,1,2.32.7.29.29,0,0,1,.08.38l-.42.66a.29.29,0,0,1-.4.15,3,3,0,0,0-1.61-.58c-.65,0-1,.4-1,.83s.45.9,1.39,1.25c1.13.42,2.43,1.05,2.48,2.53a2.42,2.42,0,0,1-2.58,2.35A3.66,3.66,0,0,1,79.54,30.51Z"
                />
                <path
                  className="colour-b"
                  d="M86.16,30.32c-.12-.09-.17-.16-.06-.35l.38-.72a.28.28,0,0,1,.39-.09,2.75,2.75,0,0,0,1.75.67,1,1,0,0,0,1-.92c0-.58-.52-1-1.5-1.34S86,26.51,85.93,25.15a2.29,2.29,0,0,1,2.51-2.28,4,4,0,0,1,2.32.7.3.3,0,0,1,.09.38l-.43.67a.29.29,0,0,1-.39.15,3,3,0,0,0-1.61-.59c-.66,0-1,.4-1,.83s.44.9,1.39,1.26c1.13.41,2.43,1.05,2.47,2.53a2.42,2.42,0,0,1-2.58,2.34A3.63,3.63,0,0,1,86.16,30.32Z"
                />
              </svg>
            </A>
            <p className="footer-text">
              The ultimate music & sound effects subscription for your
              organisation. Safelist up to 10 channels and enjoy full clearance
              for paid advertising and client content.
            </p>
            {!this.props.userAuth.isPremium && (
              <A href="/pricing" className="footer-link">
                <T keyName={'footer.viewPricing'}>View Pricing</T>
              </A>
            )}
          </div>
          <div className="footer-box large">
            <ul>
              <li className="footer-link">
                <A href="/help-center/general-faqs">
                  <T keyName={'footer.helpCenter'}>Help Center</T>
                </A>
              </li>
              <li className="footer-link">
                <A href="/royalty-free-music">
                  <T keyName={'footer.royaltyFreeMusic'}>Royalty Free Music</T>
                </A>
              </li>
              <li className="footer-link">
                <A href="/press">
                  <T keyName="footer.press">Press</T>
                </A>
              </li>
              <li className="footer-link">
                <a href="https://musicvine.com/careers" target="_blank">
                  <T keyName="footer.careers">Careers</T>
                </a>
              </li>
              <li className="footer-link">
                <A href="/privacy-and-cookie-policy">
                  <T keyName={'footer.privacyPolicy'}>Privacy Policy</T>
                </A>
              </li>
              <li className="footer-link">
                <A href="/user-agreement">
                  <T keyName={'footer.userAgreement'}>User Agreement</T>
                </A>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex items-center justify-center flex-col xl:justify-between xl:flex-row clamp gap-4 border-0 border-t border-solid border-t-gray-light-200 dark:border-t-gray-dark-200">
          <p className="footer-copyright">
            Copyright © {new Date().getFullYear()} Music Vine Limited. Uppbeat
            is a Music Vine Limited brand
          </p>

          <div className="flex gap-4 flex-col lg:flex-row ">
            <div className="flex justify-center items-center">
              <div
                className="footer-social-icon"
                onClick={() => {
                  this.handleSocialClick('Discord');
                }}
              >
                <a
                  href="https://discord.gg/q5xaCqhGFC"
                  target="_blank"
                  rel="noopener"
                  title={'Discord'}
                >
                  <div className="svg-container">
                    <svg
                      width="22"
                      height="19"
                      viewBox="0 0 16 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5535 1.03729C12.5178 0.552705 11.4104 0.200531 10.2526 0C10.1104 0.257074 9.94429 0.602844 9.82976 0.877902C8.599 0.692812 7.37956 0.692812 6.17144 0.877902C6.05693 0.602844 5.88704 0.257074 5.74357 0C4.58454 0.200531 3.47584 0.553999 2.44013 1.03985C0.351095 4.19666 -0.215207 7.27505 0.0679444 10.3097C1.4535 11.3444 2.79627 11.973 4.11638 12.3843C4.44233 11.9357 4.73302 11.4588 4.98345 10.9563C4.5065 10.775 4.04969 10.5514 3.61805 10.2917C3.73256 10.2069 3.84457 10.1182 3.95279 10.027C6.58546 11.2583 9.44593 11.2583 12.0472 10.027C12.1566 10.1182 12.2686 10.2069 12.3819 10.2917C11.949 10.5527 11.4909 10.7763 11.014 10.9576C11.2644 11.4588 11.5538 11.937 11.881 12.3856C13.2024 11.9743 14.5464 11.3457 15.932 10.3097C16.2642 6.79176 15.3644 3.74164 13.5535 1.03729ZM5.34212 8.44343C4.55181 8.44343 3.9037 7.70563 3.9037 6.80718C3.9037 5.90873 4.53797 5.16966 5.34212 5.16966C6.14628 5.16966 6.79437 5.90743 6.78053 6.80718C6.78178 7.70563 6.14628 8.44343 5.34212 8.44343ZM10.6578 8.44343C9.86752 8.44343 9.21941 7.70563 9.21941 6.80718C9.21941 5.90873 9.85366 5.16966 10.6578 5.16966C11.462 5.16966 12.1101 5.90743 12.0962 6.80718C12.0962 7.70563 11.462 8.44343 10.6578 8.44343Z"
                        fill="#a6a6a6"
                      />
                    </svg>
                  </div>
                </a>
              </div>
              <div
                className="footer-social-icon"
                onClick={() => {
                  this.handleSocialClick('Twitter');
                }}
              >
                <a
                  href="https://twitter.com/UppbeatOfficial"
                  target="_blank"
                  rel="noopener"
                  title={'Twitter'}
                >
                  <div className="svg-container">
                    <TwitterSVG />
                  </div>
                </a>
              </div>
              <div
                className="footer-social-icon"
                onClick={() => {
                  this.handleSocialClick('Facebook');
                }}
              >
                <a
                  href="https://www.facebook.com/UppbeatOfficial/"
                  target="_blank"
                  rel="noopener"
                  title={'Facebook'}
                >
                  <div className="svg-container">
                    <FacebookSVG />
                  </div>
                </a>
              </div>
              <div
                className="footer-social-icon"
                onClick={() => {
                  this.handleSocialClick('Instagram');
                }}
              >
                <a
                  href="https://www.instagram.com/UppbeatOfficial/"
                  target="_blank"
                  rel="noopener"
                  title={'Instagram'}
                >
                  <div className="svg-container">
                    <InstagramSVG />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onAddModal: (modal, intent, heading) =>
    dispatch(addModal(modal, intent, heading)),
});

const mapStateToProps = (state) => ({
  ...state.footerPlayer,
  userAuth: state.userAuth,
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
