import { AnimatePresence, motion } from 'framer-motion';
import { XIcon } from 'lucide-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button } from '@/components/ui/button';
import Drawer from '@/components/ui/drawer';
import { useAppState } from '@/hooks/useAppState';
import useTWScreens from '@/hooks/useTWScreens';
import { cn } from '@/lib/utils';

import { setAsDismissedInLocalStorage } from './utils/setAsDismissedInLocalStorage';

import type { ReactNode } from 'react';

export type PopUpVariant = 'default' | 'banner-img-pu';
export type PopUpPosition =
  | 'bottom-left'
  | 'bottom-right'
  | 'top-left'
  | 'top-right'
  | 'center';

export interface PagePopupProps {
  children: ReactNode;
  localStorageKey: string;
  position?: PopUpPosition;
  variant?: PopUpVariant;
  delay?: number;
  onClickDismiss?: () => void;
  excludeSlug?: string | RegExp;
  duration?: {
    from: Date;
    to: Date;
  };
  noXIcon?: boolean;
}

const getPositionClasses = (
  pos: PopUpPosition,
  footerPlayerActive?: boolean,
): string => {
  const basePositions = {
    'bottom-left': `left-4 ${footerPlayerActive ? 'bottom-24' : 'bottom-4'}`,
    'bottom-right': `right-4 ${footerPlayerActive ? 'bottom-24' : 'bottom-4'}`,
    'top-left': 'left-4 top-4',
    'top-right': 'right-4 top-4',
    center: 'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
  };

  return basePositions[pos] || 'right-4 top-4';
};

export const createDismissHandler = ({
  setIsExiting,
  setShow,
  onClickDismiss,
  delay = 150,
}: {
  setIsExiting: (value: boolean) => void;
  setShow: (value: boolean) => void;
  onClickDismiss?: () => void;
  delay?: number;
}) => {
  return () => {
    setIsExiting(true);
    const timeoutId = setTimeout(() => {
      setShow(false);
      onClickDismiss?.();
    }, delay);

    return () => clearTimeout(timeoutId);
  };
};

export const PagePopup = ({
  children,
  localStorageKey,
  position = 'bottom-right',
  variant = 'default',
  onClickDismiss,
  delay = 150,
  noXIcon = false,
}: Omit<PagePopupProps, 'excludeSlug' | 'duration'>) => {
  const [show, setShow] = useState<boolean>(true);
  const [isExiting, setIsExiting] = useState(false);
  const { isMd } = useTWScreens();
  const footerPlayer = useAppState('footerPlayer');
  const footerPlayerActive = footerPlayer?.playerActive;

  const popInVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      y: position.includes('bottom') ? 50 : -50,
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 400,
        damping: 20,
        duration: 0.15,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      y: position.includes('bottom') ? 50 : -50,
      transition: {
        duration: 0.15,
      },
    },
  };

  const dismiss = useCallback(() => {
    setIsExiting(true);
    const timeoutId = setTimeout(() => {
      setShow(false);
      setAsDismissedInLocalStorage({ localStorageKey });
      onClickDismiss?.();
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [delay, onClickDismiss, localStorageKey]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === 'DISMISS_POPUP') {
        setIsExiting(true);
        const timeoutId = setTimeout(() => {
          setShow(false);
          setAsDismissedInLocalStorage({ localStorageKey });
          onClickDismiss?.();
        }, delay);

        return () => clearTimeout(timeoutId);
      }

      return undefined;
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, [delay, onClickDismiss, localStorageKey]);

  const handleDrawerClose = useCallback(() => {
    // setIsDrawerOpen(false);
    setShow(false);
    setAsDismissedInLocalStorage({ localStorageKey });
    onClickDismiss?.();
    window.postMessage({ type: 'DISMISS_POPUP' }, '*');
  }, [localStorageKey, onClickDismiss]);

  if (!show) {
    return null;
  }

  if (!isMd) {
    return (
      <Drawer.Container
        isOpen={show}
        setIsOpen={(open) => {
          if (!open) {
            handleDrawerClose();
          }
        }}
        className="p-0"
      >
        <Drawer.Trigger>
          <span style={{ display: 'none', zIndex: 1000 }} />
        </Drawer.Trigger>
        <Drawer.Content>
          <div
            className={cn(
              'w-full bg-white dark:bg-gray-dark-400',
              'shadow-[0px_0px_6px_1px_#F7F9F9] dark:shadow-[0px_0px_6px_1px_rgba(50,52,52,0.50)]',
              {
                'p-0': variant === 'banner-img-pu',
                'px-6 pt-8 pb-10': variant === 'default',
              },
            )}
          >
            <div className="flex flex-col items-center">
              <Button
                variant="transparent"
                size="icon"
                onClick={handleDrawerClose}
                className={cn(
                  'absolute z-10 right-2 top-2 m-2',
                  variant === 'banner-img-pu' &&
                    'text-white hover:text-gray-200',
                  noXIcon && 'hidden',
                )}
              >
                <span className="sr-only">dismiss popup</span>
                <XIcon className="w-4 h-4" />
              </Button>

              <div
                className={cn('w-full text-center', {
                  '[&_.popup-media]:!w-full': true,
                  '[&_.popup-media]:!px-0': variant === 'default',
                })}
              >
                {children}
              </div>
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Container>
    );
  }

  return (
    <AnimatePresence>
      {show && !isExiting && (
        <>
          {position === 'center' && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 dark:bg-black/70 z-40"
            />
          )}

          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={popInVariants}
            className={cn(
              'fixed z-10 w-[360px]',
              getPositionClasses(position, footerPlayerActive),
            )}
          >
            <div
              className={cn(
                'relative rounded-xl overflow-hidden pb-10',
                'bg-white dark:bg-gray-dark-400',
                'shadow-[0px_0px_6px_1px_#F7F9F9] dark:shadow-[0px_0px_6px_1px_rgba(50,52,52,0.50)]',
                'transition-opacity duration-700',
                'sm:border-solid sm:border-[1px] sm:border-[#F0F2F2] dark:sm:border-[#323434]',
                {
                  'px-[31px] pt-8': variant === 'default',
                  'p-0': variant === 'banner-img-pu',
                },
              )}
            >
              <Button
                variant="transparent"
                size="icon"
                onClick={dismiss}
                className={cn(
                  'absolute z-10 right-2 top-2 m-2',
                  variant === 'banner-img-pu' &&
                    'text-white hover:text-gray-200',
                  noXIcon && 'hidden',
                )}
              >
                <span className="sr-only">dismiss popup</span>
                <XIcon className="w-4 h-4" />
              </Button>

              {children}
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};
