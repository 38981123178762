import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import GoBusinessModal from '@/components/Modals/GoBusiness';
import GoPremiumModal from '@/components/Modals/GoPremium';
import { GoPremiumModalVariants } from '@/components/Modals/GoPremium/types';
import LoginModal from '@/components/Modals/Login';
import SignUpModal from '@/components/Modals/SignUp';
import { SignUpModalScreenName } from '@/components/Modals/SignUp/types';
import VerifyEmailModal from '@/components/Modals/VerifyEmail';
import { addConversionPoint } from '@/utils/helpers';

enum AccountModalNames {
  SignUp = 'signup',
  Login = 'login',
  GoPremium = 'go-premium',
  GoBusiness = 'go-business',
  GoPremiumTrackDownload = 'go-premium-track-download',
  GoPremiumSFXDownload = 'go-premium-sfx-download',
  GoPremiumMgDownload = 'go-premium-mg-download',
  GoPremiumNoCredits = 'go-premium-no-credits',
  SignUpFree = 'signup-free',
  SignUpPremium = 'signup-premium',
  SignUpBusiness = 'signup-business',
  SignUpBusinessAlt = 'signup-business-alt',
  VerifyEmail = 'verify-email',
}

interface AccountModalsContextType {
  currentModal: AccountModalNames | null;
  setCurrentModal: (modal: AccountModalNames, screen?: string) => void;
  handleOpenChange: () => void;
  initialScreen?: string | undefined;
}

const AccountModalsContext = createContext<AccountModalsContextType | null>(
  null,
);

const AccountModalsProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentModal, setCurrentModal] = useState<{
    modal: AccountModalNames | null;
    screen?: string;
  }>({
    modal: null,
  });

  const handleOpenChange = useCallback(() => {
    setCurrentModal({ modal: null });
  }, [setCurrentModal]);

  const providerValue = useMemo(
    () => ({
      setCurrentModal: (modal: AccountModalNames, screen?: string) => {
        setCurrentModal({ modal, screen });
      },
      currentModal: currentModal.modal,
      initialScreen: currentModal.screen,
      handleOpenChange,
    }),
    [
      currentModal.modal,
      currentModal.screen,
      setCurrentModal,
      handleOpenChange,
    ],
  );

  return (
    <AccountModalsContext.Provider value={providerValue}>
      {children}
    </AccountModalsContext.Provider>
  );
};

export enum ModalConversionPoints {
  AccountPage = 'goPremiumAccountPage',
  AccountSidebar = 'goPremiumAccountSidebar',
  AccountDownloads = 'goPremiumAccountDownloads',
  AudioAd = 'goPremiumUpbeataa',
  DownloadUpsell = 'goPremiumDlUpsell',
  DownloadPremium = 'goPremiumDlPremium',
  DownloadSfx = 'goPremiumDlSfx',
  DownloadLimit = 'goPremiumDlLimit',
  External = 'goPremiumExternal',
  Header = 'goPremiumHeader',
  PricingPage = 'goPremiumPricingPage',
  SignUp = 'goPremiumSignUp',
  HelpCenter = 'goPremiumHelpCenter',
  DownloadPremiumMg = 'goPremiumDlPremiumMg',
}

const conversionPoints: Record<ModalConversionPoints, string> = {
  [ModalConversionPoints.AccountPage]: 'premiumFeature_AccountPage',
  [ModalConversionPoints.AccountSidebar]: 'goPremium_accountSidebar',
  [ModalConversionPoints.AccountDownloads]: 'goPremium_accountDownloads',
  [ModalConversionPoints.AudioAd]: 'goPremium_uppbeatAa',
  [ModalConversionPoints.DownloadUpsell]: 'goPremium_downloadUpsell',
  [ModalConversionPoints.DownloadPremium]: 'goPremium_premiumTrack',
  [ModalConversionPoints.DownloadSfx]: 'goPremium_sfxDl',
  [ModalConversionPoints.DownloadLimit]: 'goPremium_downloadLimit',
  [ModalConversionPoints.External]: 'goPremium_external',
  [ModalConversionPoints.Header]: 'goPremium_header',
  [ModalConversionPoints.PricingPage]: 'goPremium_PricingPage',
  [ModalConversionPoints.SignUp]: 'goPremium_signUp',
  [ModalConversionPoints.HelpCenter]: 'goPremium_helpCenter',
  [ModalConversionPoints.DownloadPremiumMg]: 'goPremium_downloadPremiumMg',
};

const useAccountModals = () => {
  const context = useContext(AccountModalsContext);

  if (!context) {
    throw new Error(
      'useAccountModals must be used within an AccountModalsProvider',
    );
  }

  const openModalWithConversionPoint = useCallback(
    (
      modal: AccountModalNames,
      conversionPoint: ModalConversionPoints,
      tag?: number,
    ) => {
      context.setCurrentModal(modal);

      addConversionPoint(
        conversionPoint,
        conversionPoints[conversionPoint],
        tag,
      );

      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('[TRACKING] Conversion point added:', conversionPoint);
      }
    },
    [context],
  );

  return { ...context, openModalWithConversionPoint };
};

const AccountModals = () => {
  const { currentModal, handleOpenChange, initialScreen } = useAccountModals();

  const initialSignUpModalScreen = useMemo(() => {
    if (currentModal === AccountModalNames.SignUpPremium) {
      return SignUpModalScreenName.SignUpPremium;
    }

    if (currentModal === AccountModalNames.SignUpBusiness) {
      return SignUpModalScreenName.SignUpBusiness;
    }

    if (currentModal === AccountModalNames.SignUpFree) {
      return SignUpModalScreenName.SignUpFree;
    }

    if (currentModal === AccountModalNames.SignUpBusinessAlt) {
      return SignUpModalScreenName.SignUpBusinessAlt;
    }

    return SignUpModalScreenName.SignUpCreator;
  }, [currentModal]);

  return (
    <>
      <SignUpModal
        open={
          currentModal === AccountModalNames.SignUp ||
          currentModal === AccountModalNames.SignUpPremium ||
          currentModal === AccountModalNames.SignUpBusiness ||
          currentModal === AccountModalNames.SignUpFree ||
          currentModal === AccountModalNames.SignUpBusinessAlt
        }
        onOpenChange={handleOpenChange}
        initial={
          (initialScreen as SignUpModalScreenName | undefined) ??
          initialSignUpModalScreen
        }
      />
      <LoginModal
        open={currentModal === AccountModalNames.Login}
        onOpenChange={handleOpenChange}
        initial={initialScreen}
      />
      <GoPremiumModal
        open={
          currentModal === AccountModalNames.GoPremium ||
          currentModal === AccountModalNames.GoPremiumTrackDownload ||
          currentModal === AccountModalNames.GoPremiumSFXDownload ||
          currentModal === AccountModalNames.GoPremiumMgDownload ||
          currentModal === AccountModalNames.GoPremiumNoCredits
        }
        onOpenChange={handleOpenChange}
        initial={initialScreen}
        variant={
          currentModal === AccountModalNames.GoPremiumTrackDownload
            ? GoPremiumModalVariants.TrackDownload
            : currentModal === AccountModalNames.GoPremiumSFXDownload
            ? GoPremiumModalVariants.SFXDownload
            : currentModal === AccountModalNames.GoPremiumNoCredits
            ? GoPremiumModalVariants.NoCredits
            : currentModal === AccountModalNames.GoPremiumMgDownload
            ? GoPremiumModalVariants.MGDownload
            : GoPremiumModalVariants.Default
        }
      />
      <GoBusinessModal
        open={currentModal === AccountModalNames.GoBusiness}
        onOpenChange={handleOpenChange}
        initial={initialScreen}
      />
      <VerifyEmailModal
        open={currentModal === AccountModalNames.VerifyEmail}
        onOpenChange={handleOpenChange}
      />
    </>
  );
};

export {
  AccountModalNames,
  AccountModals,
  AccountModalsProvider,
  useAccountModals,
};
