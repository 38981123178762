import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';

import {
  PagePopup,
  type PagePopupProps,
  PagePopupSeries,
  PopupActions,
  PopupContent,
  PopupMedia,
  type PopUpPosition,
} from '@/components/PagePopup';
import { getNextInQueue } from '@/components/PagePopup/utils/getNextInQueue';
import { setAsDismissedInLocalStorage } from '@/components/PagePopup/utils/setAsDismissedInLocalStorage';
import { Button } from '@/components/ui/button';
import { Text } from '@/components/ui/typography/text';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';
import { useHypertune } from '@/generated/hypertune.react';
import {
  MGBetaStatus,
  useMGBetaEligibility,
} from '@/hooks/useMGBetaEligibility';
import { useSettings } from '@/hooks/useSettings';

const { audioKitGiveawayNov24, welcomePopupSeries } = LOCAL_STORAGE_KEYS;

export interface PagePopupQueueProps {
  items: PagePopupProps[];
}

const PagePopupQueue = () => {
  const router = useRouter();
  const isMotionGraphicsPage = router.pathname === '/motion-graphics';
  const { pathname } = router;
  const { settings } = useSettings();

  const hypertune = useHypertune();

  const audioKitGiveawayCTA = hypertune.audioKitGiveawayCTA({
    fallback: 'Learn more',
  });

  const {
    isEligible: isMGBetaEligible,
    hasOptedIn,
    isLoading: isMGBetaEligibilityLoading,
    updateOptIn,
  } = useMGBetaEligibility();

  const handlePopupAction = useCallback(
    async (status: MGBetaStatus) => {
      await updateOptIn(status);
      setAsDismissedInLocalStorage({
        localStorageKey: 'motion-graphics-early-access',
      });
      window.postMessage({ type: 'DISMISS_POPUP' }, '*');
    },
    [updateOptIn],
  );

  const [currentPopup, setCurrentPopup] = useState<
    'initial' | 'success' | null
  >('initial');

  const handleEarlyAccessSuccess = useCallback(async () => {
    await updateOptIn(MGBetaStatus.OptedIn);
    setAsDismissedInLocalStorage({
      localStorageKey: 'motion-graphics-early-access',
    });
    setCurrentPopup('success');
  }, [updateOptIn]);

  const items: PagePopupProps[] = useMemo(
    () => [
      // Beta eligibility popups
      ...(isMGBetaEligible && !hasOptedIn && currentPopup === 'initial'
        ? [
            {
              localStorageKey: 'motion-graphics-early-access',
              variant: 'banner-img-pu' as const,
              position: 'bottom-right' as PopUpPosition,
              noXIcon: true,
              children: (
                <PopupContent
                  variant="banner-img-pu"
                  subtitle="🔑 EXCLUSIVE EARLY ACCESS"
                  mainTitle="Try our new Motion Graphics library!"
                >
                  {settings?.isPremium || settings?.isBusiness ? (
                    <Text className="text-pretty">
                      Be the first to explore our shiny new Motion Graphics
                      Library, packed with eye-catching assets to make your
                      content pop!
                    </Text>
                  ) : (
                    <Text className="text-pretty">
                      Be the first to explore our new Motion Graphics Library,
                      packed with eye-catching assets — plus, enjoy 3 extra
                      downloads on us!
                    </Text>
                  )}
                  <PopupMedia variant="banner-img-pu">
                    <video
                      src="https://fastly-cdn.uppbeat.io/popups/Opt-in.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline
                      className="object-cover w-full h-full"
                      crossOrigin="anonymous"
                    />
                  </PopupMedia>
                  <PopupActions
                    reoccurring
                    onRemindLater={() => {
                      void handlePopupAction(MGBetaStatus.RemindMeLater);
                    }}
                    onNotInterested={() => {
                      void handlePopupAction(MGBetaStatus.NotInterested);
                    }}
                  >
                    <Button
                      variant="primary"
                      onClick={handleEarlyAccessSuccess}
                      className="w-full"
                    >
                      Activate my early access
                    </Button>
                  </PopupActions>
                </PopupContent>
              ),
            },
          ]
        : []),
      ...(currentPopup === 'success'
        ? [
            {
              localStorageKey: 'motion-graphics-early-access-success',
              variant: 'banner-img-pu' as const,
              position: 'bottom-right' as PopUpPosition,
              children: (
                <PopupContent
                  variant="banner-img-pu"
                  subtitle="🔑 EXCLUSIVE EARLY ACCESS"
                  mainTitle="You're in!"
                >
                  <Text>
                    Welcome to the club! Your early access to the Motion
                    Graphics library is ready.
                  </Text>
                  <PopupMedia variant="banner-img-pu">
                    <video
                      src="https://fastly-cdn.uppbeat.io/popups/Opt-in-Success.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline
                      className="object-cover w-full h-full"
                      crossOrigin="anonymous"
                    />
                  </PopupMedia>
                  <PopupActions>
                    <div className="flex items-center justify-end gap-3 w-full">
                      <Button
                        variant="outline"
                        onClick={() => {
                          setCurrentPopup(null);
                          setAsDismissedInLocalStorage({
                            localStorageKey:
                              'motion-graphics-early-access-success',
                          });
                          window.postMessage({ type: 'DISMISS_POPUP' }, '*');
                        }}
                        className="flex-grow-[1]"
                        border
                      >
                        Skip
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          setCurrentPopup(null);
                          void router.push('/motion-graphics');
                        }}
                        className="flex-grow-[2]"
                      >
                        Browse Motion Graphics
                      </Button>
                    </div>
                  </PopupActions>
                </PopupContent>
              ),
            },
          ]
        : []),
      ...(isMGBetaEligible && hasOptedIn && isMotionGraphicsPage
        ? [
            {
              localStorageKey: welcomePopupSeries,
              variant: 'default' as const,
              position: 'bottom-right' as PopUpPosition,
              children: (
                <PagePopupSeries
                  localStorageKey={welcomePopupSeries}
                  variant="default"
                  position="bottom-right"
                  allowPrevious={false}
                  items={[
                    {
                      id: 'step-1',
                      children: (
                        <PopupContent
                          variant="default"
                          mainTitle="Welcome to Early Access!"
                        >
                          <Text>
                            Woohoo! You’ve got first dibs on our new Motion
                            Graphics Library.
                          </Text>
                          <PopupMedia
                            variant="default"
                            videoAspectRatio={298 / 132}
                          >
                            <video
                              src="https://fastly-cdn.uppbeat.io/popups/Animation1b-fin.mp4"
                              autoPlay
                              muted
                              loop
                              playsInline
                              className="object-cover w-full h-full"
                              crossOrigin="anonymous"
                            />
                          </PopupMedia>
                        </PopupContent>
                      ),
                    },
                    {
                      id: 'step-2',
                      children: (
                        <PopupContent
                          variant="default"
                          mainTitle="Exciting updates on the way!"
                        >
                          <Text>
                            We’re fine-tuning and adding features before a full
                            launch in early 2025.
                          </Text>
                          <PopupMedia
                            variant="default"
                            videoAspectRatio={298 / 132}
                          >
                            <video
                              src="https://fastly-cdn.uppbeat.io/popups/Animation2.mp4"
                              autoPlay
                              muted
                              loop
                              playsInline
                              className="object-cover w-full h-full"
                              crossOrigin="anonymous"
                            />
                          </PopupMedia>
                        </PopupContent>
                      ),
                    },
                    {
                      id: 'step-3',
                      children: (
                        <PopupContent
                          variant="default"
                          mainTitle="We’d love your feedback!"
                        >
                          <Text>
                            Your thoughts can help shape the future of our
                            Motion Graphics Library. Share them{' '}
                            <Link
                              href="https://uppbeat.typeform.com/to/GmeMYyFA"
                              className="text-uppbeat hover:underline"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              here
                            </Link>
                            !
                          </Text>
                          <PopupMedia
                            variant="default"
                            videoAspectRatio={298 / 132}
                          >
                            <video
                              src="https://fastly-cdn.uppbeat.io/popups/Animation3.mp4"
                              autoPlay
                              muted
                              loop
                              playsInline
                              className="object-cover w-full h-full"
                              crossOrigin="anonymous"
                            />
                          </PopupMedia>
                        </PopupContent>
                      ),
                    },
                  ]}
                  onClickDismiss={() => {}}
                />
              ),
            },
          ]
        : []),
      // Audio kit giveaway - show independently of other conditions
      {
        localStorageKey: audioKitGiveawayNov24,
        variant: 'default' as const,
        position: 'bottom-right' as PopUpPosition,
        children: (
          <PopupContent
            variant="default"
            subtitle="INSTAGRAM GIVEAWAY"
            mainTitle="Win the ultimate audio kit!"
          >
            <PopupMedia variant="default">
              <Image
                src="/images/audio-kit-giveaway.png"
                alt="Audio kit giveaway"
                fill
                className="object-cover"
              />
            </PopupMedia>
            <Text>
              Shure Podcast Kit, Sony Headphones and 1 year of Uppbeat Premium.
            </Text>
            <PopupActions>
              <Button
                variant="primary"
                onClick={() => {
                  hypertune.audioKitGiveawayEnter();
                }}
              >
                {audioKitGiveawayCTA}
              </Button>
            </PopupActions>
          </PopupContent>
        ),
        duration: {
          // TO-DO: Use past date to disable this popup for now
          // until it is clear when it should go live next year
          from: new Date('1999-11-21T06:00:00Z'),
          to: new Date('1999-12-05T00:00:00Z'),
        },
        delay: 7000,
        onClickDismiss: () => {
          hypertune.audioKitGiveawayDismiss();
        },
      },
    ],
    [
      isMGBetaEligible,
      hasOptedIn,
      isMotionGraphicsPage,
      currentPopup,
      handlePopupAction,
      handleEarlyAccessSuccess,
      hypertune,
      audioKitGiveawayCTA,
      router,
      settings,
    ],
  );

  const nextToShowIndex = useMemo(
    () => getNextInQueue({ items, pathname }),
    [items, pathname],
  );

  if (isMGBetaEligibilityLoading) {
    return null;
  }

  if (!items || items.length === 0) {
    return null;
  }

  if (nextToShowIndex < 0 || nextToShowIndex >= items.length) {
    return null;
  }

  const currentItem = items[nextToShowIndex];

  return <PagePopup {...currentItem}>{currentItem.children}</PagePopup>;
};

export default PagePopupQueue;
