import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/utils';

const headingVariants = cva('font-bold text-black dark:text-white font-brand', {
  variants: {
    variant: {
      h1: '~@md:~text-4xl/5xl',
      h2: '~@md:~text-3xl/4xl',
      h3: '~@md:~text-2xl/3xl',
      h4: '~@md:~text-xl/2xl',
      h5: '~@md:~text-lg/xl',
      h6: '~@md:~text-base/lg',
    },
  },
  defaultVariants: {
    variant: 'h1',
  },
});

export interface HeadingProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof headingVariants> {
  asChild?: boolean;
}

const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, variant, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : variant ?? 'h1';

    return (
      <Comp
        ref={ref}
        className={cn(headingVariants({ variant, className }))}
        {...props}
      />
    );
  },
);

Heading.displayName = 'Heading';

const Alpha = React.forwardRef<
  HTMLHeadingElement,
  Omit<HeadingProps, 'variant'>
>((props, ref) => <Heading ref={ref} variant="h1" {...props} />);

Alpha.displayName = 'Alpha';

const Bravo = React.forwardRef<
  HTMLHeadingElement,
  Omit<HeadingProps, 'variant'>
>((props, ref) => <Heading ref={ref} variant="h2" {...props} />);

Bravo.displayName = 'Bravo';

const Charlie = React.forwardRef<
  HTMLHeadingElement,
  Omit<HeadingProps, 'variant'>
>((props, ref) => <Heading ref={ref} variant="h3" {...props} />);

Charlie.displayName = 'Charlie';

const Delta = React.forwardRef<
  HTMLHeadingElement,
  Omit<HeadingProps, 'variant'>
>((props, ref) => <Heading ref={ref} variant="h4" {...props} />);

Delta.displayName = 'Delta';

const Echo = React.forwardRef<
  HTMLHeadingElement,
  Omit<HeadingProps, 'variant'>
>((props, ref) => <Heading ref={ref} variant="h5" {...props} />);

Echo.displayName = 'Echo';

const Foxtrot = React.forwardRef<
  HTMLHeadingElement,
  Omit<HeadingProps, 'variant'>
>((props, ref) => <Heading ref={ref} variant="h6" {...props} />);

Foxtrot.displayName = 'Foxtrot';

export {
  Heading,
  Alpha,
  Bravo,
  Charlie,
  Delta,
  Echo,
  Foxtrot,
  headingVariants,
};
