import * as React from 'react';

import { cn } from '@/lib/utils';

const _Tick = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 14 11"
      fill="none"
      className={cn('fill-current', className)}
      {...props}
    >
      <path d="M4.727 9.496a.887.887 0 010-1.195l6.265-6.704a.753.753 0 011.117 0 .887.887 0 010 1.195L5.844 9.496a.753.753 0 01-1.117 0z" />
      <path d="M1.558 4.935a.753.753 0 011.117 0L5.84 8.324a.887.887 0 010 1.194.753.753 0 01-1.116 0L1.558 6.13a.887.887 0 010-1.195z" />
    </svg>
  );
};

const Tick = React.memo(_Tick);

export default Tick;
