import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/utils';
import Loader from '@/styles/svg-components/Loader';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap text-base font-normal transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-uppbeat disabled:opacity-50 enabled:cursor-pointer gap-2 disabled:cursor-not-allowed dark:focus-visible:ring-offset-gray-dark-400',
  {
    variants: {
      variant: {
        primary:
          'bg-gray-dark-300 text-white enabled:hover:bg-black [&:is(a[href])]:hover:bg-black focus-visible:ring-gray-dark-300 dark:bg-gray-light-100 dark:text-black dark:enabled:hover:bg-gray-light-200 dark:[&:is(a[href])]:hover:bg-gray-light-200 dark:focus-visible:ring-gray-light-100',
        secondary:
          'bg-gray-light-100 text-black enabled:hover:bg-gray-light-300 [&:is(a[href])]:hover:bg-gray-light-300 focus-visible:ring-gray-medium-200 dark:bg-gray-dark-200 dark:text-white dark:enabled:hover:bg-gray-dark-300 dark:[&:is(a[href])]:hover:bg-gray-dark-300 dark:focus-visible:ring-gray-medium-100',
        'secondary-modal':
          'bg-gray-light-100 text-black enabled:hover:bg-gray-light-300 [&:is(a[href])]:hover:bg-gray-light-300 focus-visible:ring-gray-medium-200 dark:bg-gray-dark-200 dark:text-white dark:enabled:hover:bg-gray-dark-200/50 dark:[&:is(a[href])]:hover:bg-gray-dark-200/50 dark:focus-visible:ring-gray-medium-100',
        tertiary:
          'text-uppbeat bg-uppbeat-light enabled:hover:bg-uppbeat-medium [&:is(a[href])]:hover:bg-uppbeat-medium dark:bg-gray-dark-100 dark:text-white dark:enabled:hover:bg-gray-dark-200 dark:[&:is(a[href])]:hover:bg-gray-dark-200',
        transparent:
          'bg-transparent enabled:hover:bg-gray-light-200 [&:is(a[href])]:hover:bg-gray-light-200 text-black dark:text-white dark:enabled:hover:bg-gray-dark-300 dark:[&:is(a[href])]:hover:bg-gray-dark-300',
        destructive:
          'bg-red text-white enabled:hover:bg-red-dark [&:is(a[href])]:hover:bg-red-dark focus-visible:ring-red',
        success:
          'bg-green text-white enabled:hover:bg-green-dark [&:is(a[href])]:hover:bg-green-dark focus-visible:ring-green',
        uppbeat:
          'bg-uppbeat text-white enabled:hover:bg-uppbeat-dark [&:is(a[href])]:hover:bg-uppbeat-dark',
        free: 'bg-free text-black enabled:hover:bg-free-dark [&:is(a[href])]:hover:bg-free-dark focus-visible:ring-free',
        business:
          'bg-business text-black enabled:hover:bg-business-dark [&:is(a[href])]:hover:bg-business-dark focus-visible:ring-business',
        discord:
          'bg-gray-light-100 text-black enabled:hover:bg-discord [&:is(a[href])]:hover:bg-discord focus-visible:ring-discord dark:bg-gray-dark-200 dark:text-white dark:enabled:hover:bg-discord dark:[&:is(a[href])]:hover:bg-discord dark:focus-visible:ring-discord enabled:hover:text-white [&:is(a[href])]:hover:text-white',
        white:
          'bg-white text-black enabled:hover:bg-gray-light-100 [&:is(a[href])]:hover:bg-gray-light-100',
        outline:
          'bg-white text-gray-dark-200 enabled:hover:bg-gray-light-100 [&:is(a[href])]:hover:bg-gray-light-300 focus-visible:ring-gray-medium-200 dark:bg-gray-dark-300 dark:text-white dark:enabled:hover:bg-gray-dark-200 dark:[&:is(a[href])]:hover:bg-gray-dark-300 dark:focus-visible:ring-gray-medium-100 border-gray-light-300 enabled:hover:border-gray-light-400 dark:border-gray-dark-200 dark:enabled:hover:border-gray-dark-100 [&:is(a[href])]:hover:border-gray-light-300 dark:[&:is(a[href])]:hover:border-gray-dark-300',
      },
      size: {
        default: 'h-8 px-4 py-2 leading-8',
        xs: 'h-5 px-2 text-xs leading-5 gap-1.5',
        sm: 'h-6 px-3 text-sm leading-6',
        lg: 'h-10 px-8 text-lg leading-10 gap-4',
        icon: 'h-8 w-8 leading-8',
        'icon-xs': 'h-5 w-5 leading-5',
        'icon-sm': 'h-6 w-6 leading-5',
      },
      borderRadius: {
        default: 'rounded-btn',
        sm: 'rounded-btn-sm',
        full: 'rounded-full',
      },
      border: {
        true: 'border border-solid',
        false: 'border-none',
      },
    },
    compoundVariants: [
      {
        size: 'sm',
        borderRadius: 'default',
        className: 'rounded-lg',
      },
      {
        size: 'xs',
        borderRadius: 'default',
        className: 'rounded-md',
      },
      {
        variant: 'primary',
        border: true,
        className: 'border-gray-dark-400 dark:border-gray-medium-100',
      },
      {
        variant: 'secondary',
        border: true,
        className: 'border-gray-light-200 dark:border-gray-dark-100',
      },
      {
        variant: 'tertiary',
        border: true,
        className: 'border-uppbeat-medium dark:border-gray-medium-400',
      },
      {
        variant: 'transparent',
        border: true,
        className: 'border-gray-light-300 dark:border-gray-dark-200',
      },
      { variant: 'destructive', border: true, className: 'border-red-dark' },
      { variant: 'success', border: true, className: 'border-green-dark' },
      { variant: 'uppbeat', border: true, className: 'border-uppbeat-dark' },
      { variant: 'free', border: true, className: 'border-free-dark' },
      { variant: 'business', border: true, className: 'border-business-dark' },
      {
        variant: 'discord',
        border: true,
        className:
          'border-gray-light-300 dark:border-gray-dark-100 [&:is(a[href])]:hover:border-discord-dark enabled:hover:border-discord-dark',
      },
      { variant: 'white', border: true, className: 'border-gray-light-300' },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'default',
      borderRadius: 'default',
      border: false,
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      borderRadius,
      border,
      asChild = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        className={cn(
          buttonVariants({
            variant,
            size,
            borderRadius,
            border,
            className,
          }),
        )}
        ref={ref}
        type={asChild ? undefined : props.type ?? 'button'}
        {...props}
      >
        {props?.children}
      </Comp>
    );
  },
);

Button.displayName = 'Button';

const loadingVariants = cva('w-4 hidden', {
  variants: {
    size: {
      default: 'w-4',
      lg: 'w-5',
      sm: 'w-3',
      xs: 'w-3',
      icon: 'w-4',
      'icon-xs': 'w-4',
    },
    visible: {
      true: 'block',
      false: 'hidden',
    },
  },
  defaultVariants: {
    size: 'default',
    visible: false,
  },
});

const Loading = ({ size, visible }: VariantProps<typeof loadingVariants>) => {
  return <Loader className={cn(loadingVariants({ size, visible }))} />;
};

export { Button, buttonVariants, Loading, loadingVariants };
