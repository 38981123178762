import clsx from 'clsx';
import { ChevronsLeft } from 'lucide-react';
import { memo, useCallback, useContext } from 'react';
import { useMedia } from 'react-use';

import { DynamicLink } from '@/components/DynamicLink';
import Discord from '@/components/Icons/SocialMono/Discord';
import { UBLogo, UBLogoSmall } from '@/components/Logo';
import ThemeToggle from '@/components/ThemeToggle';
import Tooltip from '@/components/Tooltip';
import { Button } from '@/components/ui/button';
import { SidebarContext } from '@/providers/SidebarProvider';

import styles from './styles.module.css';
import UBSidebarLinks from './UBSidebarLinks';

const UBSidebar = memo(() => {
  const { toggleSidebar, isSidebarToggled, isSearchVisible } =
    useContext(SidebarContext);

  const isSidebarAutoToggled = useMedia('(max-width: 991px)', false);
  const isSidebarClosed = isSidebarToggled || isSidebarAutoToggled;

  const onToggleSidebar = useCallback(() => {
    toggleSidebar();
  }, [toggleSidebar]);

  const onCloseSidebar = useCallback(() => {
    toggleSidebar(false);
  }, [toggleSidebar]);

  return (
    <section
      className={clsx(
        styles.sidebar,
        isSidebarToggled && styles.toggled,
        isSearchVisible && styles.searchVisible,
        'z-30',
      )}
      data-sidebar-closed={isSidebarClosed}
    >
      <div className={styles.sidebarTop}>
        <DynamicLink
          href="/"
          aria-label="Uppbeat homepage"
          onClick={onCloseSidebar}
        >
          <UBLogoSmall />
          <UBLogo />
        </DynamicLink>
      </div>
      <div className={styles.sidebarLinks}>
        <UBSidebarLinks />
      </div>
      <div className="flex items-center sidebarVisible:justify-start gap-1 px-4 py-5 border-t border-solid border-0 border-gray-light-100 dark:border-gray-dark-100 flew-row headerFixed:[[data-sidebar-closed=true]_&]:flex-col-reverse justify-between sidebarVisible:flex-col-reverse sidebarVisible:[[data-sidebar-closed=false]_&]:flex-row">
        <Tooltip
          label={isSidebarToggled ? 'Expand sidebar' : 'Collapse sidebar'}
        >
          <Button
            variant="transparent"
            size="icon"
            borderRadius="full"
            aria-label="Collapse sidebar"
            className="flex-shrink-0 hidden sidebarVisible:inline-flex"
            onClick={onToggleSidebar}
          >
            <ChevronsLeft
              className={clsx(
                'size-4 fill-none transition-transform duration-300',
                isSidebarClosed && 'rotate-180',
              )}
            />
          </Button>
        </Tooltip>
        <ThemeToggle />
        <Tooltip label="Join the Uppbeat Discord server">
          <Button
            asChild
            variant={isSidebarClosed ? 'transparent' : 'discord'}
            borderRadius={isSidebarClosed ? 'full' : 'default'}
            size={isSidebarClosed ? 'icon' : 'default'}
            className="sidebarVisible:[[data-sidebar-closed=false]_&]:ml-auto"
          >
            <a
              href="https://discord.gg/q5xaCqhGFC"
              target="_blank"
              rel="noreferrer"
            >
              <Discord className="size-4" />
              <span className="sr-only sidebarVisible:[[data-sidebar-closed=false]_&]:not-sr-only">
                Community
              </span>
            </a>
          </Button>
        </Tooltip>
      </div>
      <div className="ub-sidebar-bg" onClick={onToggleSidebar} />
    </section>
  );
});

UBSidebar.displayName = 'UBSidebar';

export default UBSidebar;
