import Switch from '@/components/Switch';
import { useLocalSettings } from '@/providers/LocalSettingsProvider';

const ToggleSEOData = () => {
  const { localSettings, setLocalSettings } = useLocalSettings();

  let { isShowSEOData } = localSettings;

  const onToggleSEOData = () => {
    isShowSEOData = !isShowSEOData;
    setLocalSettings({
      ...localSettings,
      isShowSEOData,
    });
  };

  return (
    <Switch
      onCheckedChange={onToggleSEOData}
      checked={!!isShowSEOData}
      id="admin-seo"
      label="Show SEO Data"
    />
  );
};

export default ToggleSEOData;
