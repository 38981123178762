import * as Sentry from '@sentry/nextjs';
import axios from 'axios';

import { shouldAvoidCdn } from './cdn';

const api = axios.create({
  withCredentials: true,
});

// Axios request interceptor
api.interceptors.request.use((config) => {
  const avoidCdn = shouldAvoidCdn() || config.headers['X-Skip-CDN'] === 'true';
  // Check if the request method is uncachable
  const isCachable = ['GET', 'HEAD'].includes(
    config.method?.toUpperCase() ?? '',
  );

  // Set baseURL based on the request method
  config.baseURL =
    !isCachable || avoidCdn
      ? process.env.NEXT_PUBLIC_API
      : process.env.NEXT_PUBLIC_CDN_API;

  if (avoidCdn) {
    config.headers['Cache-Control'] =
      'no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0';
    config.headers.Pragma = 'no-cache';
    config.headers.Expires = '0';
    // Remove the custom header to avoid sending it to the server
    delete config.headers['X-Skip-CDN'];
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 500) {
      Sentry.captureException(error, {
        extra: {
          url: error.config.url,
          method: error.config.method,
          data: error.config.data,
        },
      });
    }

    return Promise.reject(error);
  },
);

export { api };
