import React from 'react';

import { Foxtrot } from '@/components/ui/typography/heading';
import { cn } from '@/lib/utils';

import { PopupMedia } from './PopupMedia';

import type { PopUpVariant } from './PagePopup';
import type { ReactNode } from 'react';

interface PopupContentProps {
  subtitle?: string;
  mainTitle?: string;
  children: ReactNode;
  className?: string;
  variant?: PopUpVariant;
}

const hasContent = (str: string | undefined): boolean => {
  return typeof str === 'string' && str.length > 0;
};

export const PopupContent = ({
  subtitle,
  mainTitle,
  children,
  className,
  variant = 'default',
}: PopupContentProps) => {
  // Split children into media and non-media content
  const childrenArray = React.Children.toArray(children);
  const mediaContent = childrenArray.find(
    (child) => React.isValidElement(child) && child.type === PopupMedia,
  );

  const otherContent = childrenArray.filter(
    (child) => !React.isValidElement(child) || child.type !== PopupMedia,
  );

  if (variant === 'banner-img-pu') {
    return (
      <div className="flex flex-col h-full">
        {mediaContent}
        <div className="p-5 space-y-4 flex-grow px-8">
          {hasContent(subtitle) && (
            <div className="font-sans text-uppbeat text-xs">{subtitle}</div>
          )}
          {hasContent(mainTitle) && <Foxtrot>{mainTitle}</Foxtrot>}
          {otherContent}
        </div>
      </div>
    );
  }

  return (
    <div className={cn('space-y-4 h-full', className)}>
      {hasContent(subtitle) && (
        <div className="font-sans text-uppbeat text-xs mb-0.5">{subtitle}</div>
      )}
      {hasContent(mainTitle) && <Foxtrot>{mainTitle}</Foxtrot>}
      <div className="space-y-6">{children}</div>
    </div>
  );
};

export const PopupActions = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex justify-end items-center gap-3 mt-8">{children}</div>
  );
};
