import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { cva, type VariantProps } from 'class-variance-authority';
import React, { useState } from 'react';

import { cn } from '@/lib/utils';

const tooltipContentVariants = cva(
  'isolate z-50 rounded-lg px-2.5 py-1 text-sm data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade select-none leading-normal will-change-[transform,opacity] shadow-card border-1 border-solid',
  {
    variants: {
      variant: {
        default:
          'text-black dark:text-white bg-white dark:bg-gray-dark-300 border-gray-light-300 dark:border-gray-dark-300',
        inverted:
          'text-white dark:text-black bg-gray-dark-300 dark:bg-white border-gray-dark-300 dark:border-gray-light-300',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

const tooltipArrowVariants = cva('fill-current', {
  variants: {
    variant: {
      default: 'text-white dark:text-gray-dark-300',
      inverted: 'text-gray-dark-300 dark:text-white',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export interface TooltipProps
  extends VariantProps<typeof tooltipContentVariants> {
  children: React.ReactNode;
  label?: React.ReactNode;
  className?: string;
  delayDuration?: number;
  side?: TooltipPrimitive.TooltipContentProps['side'];
  align?: TooltipPrimitive.TooltipContentProps['align'];
  hideOnFocus?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  label,
  className,
  delayDuration = 500,
  side = 'top',
  align = 'center',
  hideOnFocus = true,
  variant,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFocusVisible, setIsFocusVisible] = useState(false);

  if (!label) {
    return children;
  }

  return (
    <TooltipPrimitive.Provider delayDuration={delayDuration}>
      <TooltipPrimitive.Root
        onOpenChange={setIsOpen}
        open={isOpen}
        defaultOpen={false}
        disableHoverableContent
      >
        <TooltipPrimitive.Trigger
          asChild
          onFocus={(e) => {
            if (e.target.matches(':focus-visible')) {
              setIsFocusVisible(true);
            }
          }}
          onBlur={() => {
            setIsFocusVisible(false);
          }}
        >
          {children}
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            side={side}
            align={align}
            sideOffset={5}
            className={cn(
              tooltipContentVariants({ variant }),
              className,
              isFocusVisible && hideOnFocus && 'sr-only',
            )}
            collisionPadding={{ top: 16, right: 16, bottom: 16, left: 16 }}
          >
            {label}
            <TooltipPrimitive.Arrow
              className={cn(tooltipArrowVariants({ variant }))}
              width={9}
              height={4}
            />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};

export default Tooltip;
