import { AnimatePresence, motion, type Variants } from 'framer-motion';
import React, { useCallback, useState } from 'react';

import { Button } from '@/components/ui/button';
import { Text } from '@/components/ui/typography/text';

import { type PagePopupProps } from './PagePopup';
import { PopupActions } from './PopupActions';
import { PopupContent } from './PopupContent';
import { setAsDismissedInLocalStorage } from './utils/setAsDismissedInLocalStorage';

interface SeriesItem {
  id: string;
  subtitle?: string;
  mainTitle?: string;
  children: React.ReactNode;
}

interface PagePopupSeriesProps {
  items: SeriesItem[];
  allowPrevious?: boolean;
  onClickDismiss: PagePopupProps['onClickDismiss'];
  variant: PagePopupProps['variant'];
  position: PagePopupProps['position'];
  localStorageKey: string;
}

export const PagePopupSeries = ({
  items,
  allowPrevious = true,
  onClickDismiss,
  variant,
  position = 'bottom-right',
  localStorageKey,
}: PagePopupSeriesProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [show, setShow] = useState(true);

  const dismiss = useCallback(() => {
    const timeoutId = setTimeout(() => {
      setShow(false);
      setAsDismissedInLocalStorage({ localStorageKey });
      onClickDismiss?.();
    }, 150);

    return () => clearTimeout(timeoutId);
  }, [onClickDismiss, localStorageKey]);

  if (!items || items.length === 0) {
    return null;
  }

  const currentItem = items[currentIndex];
  const isFirstItem = currentIndex === 0;
  const isLastItem = currentIndex === items.length - 1;

  const handleNext = () => {
    setDirection(1);

    if (!isLastItem) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    setDirection(-1);

    if (!isFirstItem) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  const slideVariants: Variants = {
    enter: (dir: number) => ({
      x: dir > 0 ? 300 : -300,
      opacity: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
    }),
    center: {
      x: 0,
      opacity: 1,
      position: 'relative',
      width: '100%',
      height: '100%',
      transition: {
        x: { type: 'spring', duration: 0.5, bounce: 0 },
        opacity: { duration: 0.1 },
      },
    },
    exit: (dir: number) => ({
      x: dir < 0 ? 300 : -300,
      opacity: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      transition: {
        x: { type: 'spring', duration: 0.5, bounce: 0 },
        opacity: { duration: 0.1 },
      },
    }),
  };

  const exitVariants: Variants = {
    initial: {
      opacity: 1,
      scale: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      y: position.includes('bottom') ? 50 : -50,
      transition: {
        duration: 0.15,
      },
    },
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          variants={exitVariants}
          initial="initial"
          animate="initial"
          exit="exit"
          className="h-full"
        >
          <div className="relative h-full">
            <AnimatePresence initial={false} custom={direction} mode="sync">
              <motion.div
                key={currentIndex}
                custom={direction}
                variants={slideVariants}
                initial="enter"
                animate="center"
                exit="exit"
              >
                <PopupContent
                  variant={variant}
                  subtitle={currentItem.subtitle}
                  mainTitle={currentItem.mainTitle}
                  className="h-full"
                >
                  <div className="flex flex-col h-full">
                    <div className="flex-grow">{currentItem.children}</div>
                    <PopupActions className="p-0">
                      <div className="flex items-center justify-between w-full gap-2">
                        <Text className="font-sans text-sm text-gray-600 dark:text-gray-300">
                          {currentIndex + 1} of {items.length}
                        </Text>

                        <div className="flex gap-2">
                          {allowPrevious && !isFirstItem && (
                            <Button
                              variant="outline"
                              border
                              onClick={handlePrevious}
                            >
                              Previous
                            </Button>
                          )}
                          {isLastItem ? (
                            <Button
                              variant="primary"
                              onClick={() => {
                                dismiss();

                                if (window.parent) {
                                  window.parent.postMessage(
                                    { type: 'DISMISS_POPUP' },
                                    '*',
                                  );
                                }
                              }}
                            >
                              Done
                            </Button>
                          ) : (
                            <Button variant="primary" onClick={handleNext}>
                              Continue
                            </Button>
                          )}
                        </div>
                      </div>
                    </PopupActions>
                  </div>
                </PopupContent>
              </motion.div>
            </AnimatePresence>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
