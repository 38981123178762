import { FlagValues } from '@vercel/flags/react';
import { mountVercelToolbar } from '@vercel/toolbar';
import { Suspense, useEffect } from 'react';

import { useHypertune } from '@/generated/hypertune.react';
import { useSettings } from '@/hooks/useSettings';

import type { ObjectValue, Value } from 'hypertune';

function getVercelFlagValuesEntries(
  keyPrefix: string,
  sourceObject: ObjectValue,
): [string, Value][] {
  return Object.entries(sourceObject).flatMap(([flagKey, flagValue]) => {
    if (flagKey.startsWith('__') || Array.isArray(flagValue)) {
      return [];
    }

    if (typeof flagValue !== 'object') {
      return [[`${keyPrefix}${flagKey}`, flagValue]];
    }

    return getVercelFlagValuesEntries(`${keyPrefix}${flagKey}.`, flagValue);
  });
}

export const VercelToolbar = () => {
  const hypertune = useHypertune();
  const { settings } = useSettings();

  const shouldInjectToolbar =
    process.env.NODE_ENV === 'development' || settings?.user.hasAccountAdmin;

  useEffect(() => {
    if (shouldInjectToolbar) {
      // Returns a cleanup function to unmount the toolbar
      return mountVercelToolbar();
    }

    return () => {};
  }, [shouldInjectToolbar]);

  if (!shouldInjectToolbar) {
    return null;
  }

  const flagValues = hypertune.get();

  const flattenedFlagValues = Object.fromEntries(
    getVercelFlagValuesEntries('', flagValues),
  );

  return (
    <Suspense fallback={null}>
      <FlagValues values={flattenedFlagValues} />
    </Suspense>
  );
};
