import * as RadixPopover from '@radix-ui/react-popover';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

import { useAccountModals } from '@/components/Modals/Account';
import Tooltip from '@/components/Tooltip';
import Drawer from '@/components/ui/drawer';
import useTWScreens from '@/hooks/useTWScreens';
import { TNamespaceProvider } from '@/providers/TNamespaceProvider';

import { PopoverProvider } from './context';

import type { TooltipProps } from '@/components/Tooltip';
import type { ReactNode } from 'react';

interface PopoverContainerProps {
  children: ReactNode;
  className?: string;
  align?: 'start' | 'end';
  side?: 'top' | 'bottom';
  animateOverTrigger?: boolean;
  buttonFade?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  avoidCollisions?: boolean;
}

interface SlotWithChildren {
  children: React.ReactElement;
}

interface TriggerProps {
  tooltip?: Omit<TooltipProps, 'children'>;
  children: React.ReactElement;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const PopoverTrigger = forwardRef<HTMLDivElement, TriggerProps>(
  ({ children, tooltip, ...props }, ref) => {
    const { isXs } = useTWScreens();

    if (children) {
      const clonedChildren = cloneElement(children, { ref, ...props });

      // If the user is on mobile, hide the tooltip.
      if (!isXs) {
        return clonedChildren;
      }

      return <Tooltip {...tooltip}>{clonedChildren}</Tooltip>;
    }

    return null;
  },
);

PopoverTrigger.displayName = 'PopoverTrigger';

const PopoverContent: React.FC<SlotWithChildren> = ({ children }) => {
  return children;
};

const cardAnimations = {
  initial: {
    opacity: 0,
    transform: 'scale(0.75)',
  },
  animate: {
    opacity: 1,
    transform: 'scale(1)',
  },
  exit: {
    opacity: 0,
    transform: 'scale(0.75)',
  },
};

const triggerAnimation = {
  initial: {
    opacity: 1,
    filter: 'blur(0px)',
    transform: 'scale(1)',
    transformOrigin: 'top right',
  },
  animate: {
    opacity: 0,
    filter: 'blur(1px)',
    transform: 'scale(1.2)',
    transformOrigin: 'top right',
  },
  exit: {
    opacity: 1,
    filter: 'blur(0px)',
    transform: 'scale(1)',
    transformOrigin: 'top right',
  },
};

const fadeAnimation = {
  initial: {
    opacity: 1,
    scale: 1,
    filter: 'blur(0px)',
  },
  animate: {
    opacity: 0,
    filter: 'blur(1px)',
    scale: 1,
  },
  exit: {
    opacity: 1,
    scale: 1,
    filter: 'blur(0px)',
  },
};

const PopoverContainer: React.FC<PopoverContainerProps> = memo(
  ({
    children,
    className,
    onOpenChange,
    align = 'end',
    side = 'bottom',
    animateOverTrigger = false,
    buttonFade = false,
    avoidCollisions = false,
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { currentModal } = useAccountModals();

    const router = useRouter();

    const { isXs } = useTWScreens();
    const containerRef = useRef<HTMLDivElement>(null);
    const isMobile = !isXs;

    // Focus the trigger when the popover is closed.
    const handleOpenChange = (shouldOpen: boolean) => {
      setIsOpen(shouldOpen);

      if (!shouldOpen && isOpen) {
        buttonRef.current?.focus();
      }
    };

    useEffect(() => {
      if (isOpen) {
        containerRef?.current?.focus();
      }

      if (onOpenChange) {
        onOpenChange(isOpen);
      }
    }, [isOpen, onOpenChange]);

    let trigger: ReactNode;
    let content: ReactNode;

    // Grab the trigger and content components.
    Children.forEach(children, (child) => {
      if (isValidElement(child)) {
        try {
          // Check if the child is a PopoverTrigger component
          if (child.type === PopoverTrigger) {
            // Clone the child element and add necessary props
            trigger = <PopoverTrigger ref={buttonRef} {...child.props} />;
          } else if (child.type === PopoverContent) {
            content = child;
          }
        } catch (e) {
          // Throw an error if the required components are not found
          throw new Error('Popover requires a trigger and content');
        }
      }
    });

    if (!trigger || !content) {
      throw new Error('Popover requires a trigger and content');
    }

    // Hide the hover card when the screen width changes from mobile to desktop
    // This is to prevent the hover card from rendering with a buggy anchor
    useEffect(() => {
      if (!isMobile) {
        setIsOpen(false);
      }
    }, [isMobile]);

    useEffect(() => {
      handleOpenChange(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.pathname]);

    /*
     *  Close the hover card when the user opens an account modal
     *  eg. Sign up, log in, upgrade, etc.
     */
    useEffect(() => {
      if (currentModal !== null) {
        setIsOpen(false);
      }
    }, [currentModal]);

    // If the user is on mobile, use the Drawer component
    if (isMobile) {
      return (
        <TNamespaceProvider value="modal">
          <PopoverProvider value={{ isOpen, setIsOpen }}>
            <Drawer.Container
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              className={className}
            >
              <Drawer.Trigger>{trigger}</Drawer.Trigger>
              <Drawer.Content>{content}</Drawer.Content>
            </Drawer.Container>
          </PopoverProvider>
        </TNamespaceProvider>
      );
    }

    // If the user is on desktop, use a Popover
    return (
      <TNamespaceProvider value="modal">
        <PopoverProvider value={{ isOpen, setIsOpen }}>
          <RadixPopover.Root open={isOpen} onOpenChange={handleOpenChange}>
            <div className="h-full flex justify-center">
              <motion.div
                className="flex justify-center items-center *:cursor-pointer will-change-transform"
                initial="initial"
                animate={
                  isOpen && (animateOverTrigger || buttonFade)
                    ? 'animate'
                    : 'initial'
                }
                exit="exit"
                variants={
                  buttonFade
                    ? fadeAnimation
                    : animateOverTrigger
                    ? triggerAnimation
                    : undefined
                }
                transition={{ type: 'spring', duration: 0.3, bounce: 0 }}
              >
                <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
              </motion.div>
            </div>
            <RadixPopover.Portal forceMount>
              <AnimatePresence mode="sync">
                {isOpen && (
                  <RadixPopover.Content
                    avoidCollisions={avoidCollisions}
                    className="rounded-[0.625rem] z-10 focus:border-0 focus:outline-none"
                    sideOffset={-(buttonRef.current?.offsetHeight ?? 0)}
                    onOpenAutoFocus={(e) => {
                      e.preventDefault();
                    }}
                    align={align}
                    side={side}
                    asChild
                    ref={containerRef}
                  >
                    <div>
                      <motion.div
                        key="hover-card-content"
                        className={twMerge(
                          'rounded-[0.625rem] overflow-hidden p-5 w-72 max-w-[calc(100vw-2rem)] bg-white dark:bg-gray-dark-300 shadow-card border border-solid border-gray-light-200 dark:border-gray-dark-300 dark:text-white will-change-transform',
                          className,
                        )}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={cardAnimations}
                        transition={{
                          type: 'spring',
                          duration: 0.4,
                          bounce: 0,
                        }}
                        style={{
                          transformOrigin:
                            'var(--radix-popover-content-transform-origin)',
                        }}
                      >
                        {content}
                      </motion.div>
                    </div>
                  </RadixPopover.Content>
                )}
              </AnimatePresence>
            </RadixPopover.Portal>
          </RadixPopover.Root>
        </PopoverProvider>
      </TNamespaceProvider>
    );
  },
);

PopoverContainer.displayName = 'PopoverContainer';

const Popover = {
  Container: PopoverContainer,
  Trigger: PopoverTrigger,
  Content: PopoverContent,
};

export default Popover;
