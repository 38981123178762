import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useCallback, useContext, useState } from 'react';

import { logSearch } from '@/features/SearchBar/api/logging';
import { getSuggestedResults } from '@/features/SearchBar/api/search';
import { mapAssetTypeToCollectionName } from '@/features/SearchBar/utils/mapAssetTypeToCollectionName';
import { SidebarContext } from '@/providers/SidebarProvider';
import { TNamespaceProvider } from '@/providers/TNamespaceProvider';
import { useTracking } from '@/providers/TrackingProvider';
import { trackSearchEngagement } from '@/utils/analytics';

import type { AssetType, SubmitSearchParams } from '@/features/SearchBar/types';
import type { GenericSearchResult } from '@/features/SearchPage/types';
import type { SearchResponse } from 'typesense/lib/Typesense/Documents';

const SearchContainer = dynamic(() =>
  import('./SearchContainer').then((mod) => ({
    default: mod.SearchContainer,
  })),
);

interface SearchResults {
  searchTerm: string;
  results: SearchResponse<GenericSearchResult>[];
  redirect?: SearchResponse<GenericSearchResult>[];
}

export const SearchBar: React.FC = () => {
  const [searchResults, setSearchResults] = useState<SearchResults | undefined>(
    undefined,
  );

  const { initializeTracking } = useTracking();

  const { toggleSearch, isSearchVisible } = useContext(SidebarContext);

  const router = useRouter();

  const submitSearch = useCallback(
    async ({ keyword, assetType }: SubmitSearchParams) => {
      const redirect = searchResults?.redirect;
      const assetTypeAsQueryParamValue =
        mapAssetTypeToCollectionName(assetType);

      /*
      Redirect the user to specific page only if
      there is a redirect url available based on the search input
      and the selected asset type matches that of the redirect page.
      */
      if (assetType !== 'motion' && redirect && redirect?.length > 0) {
        const { hits } = redirect[0];

        if (hits) {
          const { document } = hits[0];

          if (document.type === assetType) {
            const { slug } = document;

            await router.push(slug);

            void Promise.all([
              initializeTracking({
                endSlug: slug,
                content: document.name,
                contentType: 'Search',
                access: 'Search',
              }),
              logSearch(keyword, router.asPath, slug, 'REDIRECT'),
            ]).catch((error) => {
              console.warn('Unable to track search', error);
            });

            toggleSearch(false);

            return;
          }
        }
      }

      toggleSearch(false);
      trackSearchEngagement(keyword);

      void router.push(
        `/browse/search?query=${keyword}&type=${assetTypeAsQueryParamValue}`,
      );

      void logSearch(
        keyword,
        router.asPath,
        `/browse/search?query=${keyword}&type=${assetTypeAsQueryParamValue}`,
        'ENTER',
      );

      void initializeTracking({
        endSlug: `/browse/search?query=${keyword}&type=${assetTypeAsQueryParamValue}`,
        content: keyword,
        contentType: 'Search',
        access: 'Search',
      });
    },
    [searchResults, initializeTracking, router, toggleSearch],
  );

  const searchByKeyword = useCallback(
    async (keyword: string, assetType: AssetType) => {
      if (!keyword) {
        setSearchResults(undefined);

        return;
      }

      const [search, redirect] = await getSuggestedResults(keyword, assetType);

      setSearchResults({ searchTerm: keyword, results: search, redirect });
    },
    [],
  );

  const closeSearch = useCallback(() => {
    toggleSearch(false);
    setSearchResults(undefined);
  }, [toggleSearch]);

  if (isSearchVisible) {
    return (
      <TNamespaceProvider value="search">
        <SearchContainer
          submitSearch={submitSearch}
          openSearch={() => {
            toggleSearch(true);
          }}
          closeSearch={closeSearch}
          searchByKeyword={searchByKeyword}
          searchTerm={searchResults?.searchTerm}
          results={searchResults?.results}
        />
      </TNamespaceProvider>
    );
  }

  return null;
};
