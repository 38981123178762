/* eslint-disable react/no-array-index-key */
/* eslint-disable prettier/prettier */
// import { useContext, useState } from 'react';
import clsx from 'clsx';
import { Users } from 'lucide-react';
import { useRouter } from 'next/router';
import { memo, useCallback, useContext } from 'react';

import { DynamicLink } from '@/components/DynamicLink';
import {
  MotionGraphicsWithIconTag,
} from '@/components/Icons/Sidebar/MotionGraphics';
import { T } from '@/components/T';
import NewBadge from '@/components/ui/NewBadge';
import SearchBar from '@/features/SearchBar';
import { useHypertune } from '@/generated/hypertune.react';
import { useAppState } from '@/hooks/useAppState';
import { SidebarContext } from '@/providers/SidebarProvider';
import { useTracking } from '@/providers/TrackingProvider';
import SidebarBlog from '@/styles/svg-components/Sidebar11Blog';
import SidebarPlaylistGenerator from '@/styles/svg-components/Sidebar12AI';
import SidebarMusic from '@/styles/svg-components/Sidebar1Browse';
import SidebarTrending from '@/styles/svg-components/Sidebar2Trending';
import SidebarAccount from '@/styles/svg-components/Sidebar6AccountSettings';
import SidebarPricing from '@/styles/svg-components/Sidebar7Pricing';
import SidebarSoundEffects from '@/styles/svg-components/Sidebar8Sfx';
import SidebarHelpCenter from '@/styles/svg-components/Sidebar9HelpCenter';

import styles from './styles.module.css';

import type { SidebarSections } from './types';

const UBSidebarLinks = memo(() => {
  const router = useRouter();
  const userAuth = useAppState('userAuth');
  const { toggleSidebar, toggleSearch, isSearchVisible } =
    useContext(SidebarContext);

  const hypertune = useHypertune();
  const isMotionGraphicsEnabled = hypertune.motionGraphics({
    fallback: false,
  });

  const loggedOutOrFree =
    !userAuth?.isLoggedIn || !(userAuth?.isPremium || userAuth?.isBusiness);

  const sidebarSections: SidebarSections[] = [
    // Music Links.
    {
      name: 'Music Links',
      links: [
        {
          url: '/',
          label: 'Music',
          icon: <SidebarMusic />,
          pathnames: [
            '/browse/music/[...styleSlug]',
            '/browse/collection/[...collectionSlug]',
            '/music/[...tagSlug]',
            '/music/[...styleSlug]',
            '/similar/[artistId]/[trackId]',
            '/music',
            '/track/[artistSlug]/[trackSlug]',
            '/index',
            '/zones/[zoneSlug]',
            '/music',
          ],
        },
        {
          url: '/sfx',
          label: 'Sound effects',
          icon: <SidebarSoundEffects />,
          pathnames: [
            '/sfx/[sfxSlug]/[sfxId]/[variantId]',
            '/browse/sfx/[...styleSlug]',
            '/sfx/collection/[...collectionSlug]',
            '/sfx/tag/[...tagSlug]',
          ],
        },
        {
          url: '/motion-graphics',
          label: 'Motion graphics',
          icon: <MotionGraphicsWithIconTag />,
          suffix: <NewBadge />,
          pathnames: [
            '/motion-graphics',
            '/motion-graphics/asset/[mgSlug]',
            '/motion-graphics/[mgCategorySlug]/[mgCategoryItemSlug]',
          ],
          visible: isMotionGraphicsEnabled,
        },
        {
          url: '/browse/trending',
          label: 'Trending',
          icon: <SidebarTrending />,
          pathnames: ['/browse/trending'],
        },
        {
          url: '/ai-playlist-generator',
          label: 'Playlist generator',
          icon: <SidebarPlaylistGenerator />,
          pathnames: ['/ai-playlist-generator/[[...uuid]]'],
        },
      ],
    },
    // Pricing Links.
    {
      name: 'Pricing Links',
      classNames: [!loggedOutOrFree && '!hidden'],
      links: [
        {
          url: '/pricing',
          label: 'Pricing',
          icon: <SidebarPricing />,
          pathnames: [],
          visible: loggedOutOrFree,
        },
      ],
    },
    // Account Links.
    {
      name: 'Account Links',
      classNames: [
        !userAuth?.isArtist &&
          !userAuth?.profit_share_partner &&
          !userAuth?.affiliate_partner &&
          '!hidden',
      ],
      links: [
        {
          url: '/artist/downloads',
          label: 'Account',
          icon: <SidebarAccount />,
          pathnames: [
            '/my/receipts',
            '/artist/downloads',
            '/artist/statement',
            '/artist/insights',
          ],
          visible: !!userAuth?.isArtist,
        },
        {
          url: '/profit-share-report',
          label: 'Profit share report',
          icon: <SidebarTrending />,
          pathnames: [],
          visible: !!userAuth?.profit_share_partner,
        },
        {
          url: '/affiliate-report',
          label: 'Affiliate',
          icon: <SidebarTrending />,
          pathnames: [],
          visible: !!userAuth?.affiliate_partner,
        },
      ],
    },
    // Misc Links.
    {
      name: 'Misc Links',
      links: [
        {
          url: '/refer-and-earn',
          label: 'Refer & earn',
          icon: (
            <i>
              <Users className="size-4" strokeWidth={1} />
            </i>
          ),
          pathnames: [],
        },
        {
          url: '/help-center',
          label: 'Help center',
          icon: <SidebarHelpCenter />,
          pathnames: ['/help-center/[slug]'],
          visible: loggedOutOrFree,
        },
        {
          url: '/blog/',
          label: 'Blog',
          icon: <SidebarBlog />,
          pathnames: [],
        },
      ],
    },
  ];

  const activePath = router.pathname;

  const { initializeTracking } = useTracking();

  const handleSearchClick = useCallback(() => {
    toggleSearch();
  }, [toggleSearch]);

  const handleLinkClick = useCallback((label: string, url: string) => {
    void initializeTracking({
      content: label,
      endSlug: url,
      access: 'Sidebar',
    });
    toggleSidebar(true, 'mobile');
    toggleSearch(false);
  }, [initializeTracking, toggleSidebar, toggleSearch]);

  return (
    <>
      <SearchBar />
      <div className={clsx(styles.linksGroup, styles.searchLink)}>
        <button
          type="button"
          className={clsx(styles.sidebarLink, isSearchVisible && styles.active)}
          onClick={handleSearchClick}
        >
          <i>
            <svg
              width="14"
              height="14"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5981 7.00023C16.5981 10.8658 13.4644 13.9995 9.59879 13.9995C8.02659 13.9995 6.57547 13.4811 5.407 12.606L2.30656 15.7064C1.91607 16.0969 1.28296 16.0969 0.892476 15.7064C0.501987 15.3159 0.501987 14.6828 0.892476 14.2923L3.99291 11.192C3.11778 10.0235 2.59943 8.5724 2.59943 7.00023C2.59943 3.13465 5.73315 0.000976562 9.59879 0.000976562C13.4644 0.000976562 16.5981 3.13465 16.5981 7.00023ZM14.5983 7.00023C14.5983 9.76136 12.36 11.9997 9.59879 11.9997C6.83762 11.9997 4.59924 9.76136 4.59924 7.00023C4.59924 4.2391 6.83762 2.00076 9.59879 2.00076C12.36 2.00076 14.5983 4.2391 14.5983 7.00023Z"
                fill="currentColor"
              />
            </svg>
          </i>
          <span>
            <T keyName="Search" />
          </span>
        </button>
      </div>
      {sidebarSections.map((section) => {
        const classes = section.classNames ?? [];

        return (
          <div
            key={section.name}
            className={clsx(styles.linksGroup, ...classes)}
          >
            {section.links.map((link) => {
              if (link.visible === false) {
                return null;
              }

              const isActive =
                (activePath === link.url ||
                  activePath === `/personalized${link.url}` ||
                  link.pathnames.some(
                    (path) =>
                      activePath === path ||
                      activePath === `/personalized${path}`,
                  )) &&
                !isSearchVisible
                  ? styles.active
                  : null;

              return (
                <DynamicLink
                  external={link.external}
                  className={clsx(styles.sidebarLink, isActive)}
                  href={link.url}
                  key={link.label}
                  onClick={() => handleLinkClick(link.label, link.url)}
                >
                  {link.icon}
                  <span className="flex items-center justify-between w-full">
                    <T keyName={link.label} />
                  </span>
                  {link.suffix}
                </DynamicLink>
              );
            })}
          </div>
        );
      })}
    </>
  );
});

UBSidebarLinks.displayName = 'UBSidebarLinks';

export default UBSidebarLinks;
