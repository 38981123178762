import { hasBeenDismissed } from './hasBeenDismissed';
import { isLive } from './isLive';
import { shouldNotShowOnCurrentPage } from './shouldNotShowOnCurrentPage';

import type { PagePopupQueueProps } from '@/components/PagePopupQueue';

interface GetNextInQueueParams {
  items: PagePopupQueueProps['items'];
  pathname: string;
}

export const getNextInQueue = ({
  items,
  pathname,
}: GetNextInQueueParams): number => {
  if (!items || items.length === 0) {
    return -1;
  }

  return items.findIndex(
    (item) =>
      isLive(item.duration) &&
      !shouldNotShowOnCurrentPage({
        pathname,
        popupExcludeSlug: item.excludeSlug,
      }) &&
      !hasBeenDismissed({ localStorageKey: item.localStorageKey }),
  );
};
