import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { api } from '@/api/api';
import { getUniversalCookie, setUniversalCookie } from '@/cookies';
import { useAppState } from '@/hooks/useAppState';

interface MGBetaEligibilityResponse {
  is_eligible: boolean;
  has_opted_in: boolean;
}

export enum MGBetaStatus {
  NotEligible = 0,
  OptedIn = 1,
  Eligible = 2,
  RemindMeLater = 3,
  NotInterested = 4,
}

export interface MGBetaEligibilityCookie {
  motionGraphics: boolean;
}

const handleLocalStorageAndCookies = (
  betaEligibility: MGBetaEligibilityCookie,
) => {
  if (typeof document !== 'undefined' && betaEligibility) {
    try {
      const expiry = new Date();

      expiry.setFullYear(expiry.getFullYear() + 1);

      const cookieData = {
        motionGraphics: betaEligibility.motionGraphics,
      };

      setUniversalCookie('betaEligibility', cookieData, {
        options: { sameSite: 'strict' },
      });
    } catch (e) {
      console.warn('Something went wrong setting cookies/local storage.', e);
    }
  }
};

export const useMGBetaEligibility = () => {
  const userAuth = useAppState('userAuth');
  const queryClient = useQueryClient();

  const initialBetaData = getUniversalCookie('betaEligibility');

  let hasOptedInMotionGraphics = false;

  if (initialBetaData) {
    try {
      if (
        typeof initialBetaData === 'object' &&
        'motionGraphics' in initialBetaData
      ) {
        hasOptedInMotionGraphics = !!initialBetaData.motionGraphics;
      }
    } catch (e) {
      console.warn('Error parsing beta eligibility cookie', e);
    }
  }

  const isEnabled = userAuth?.hasLoaded === true && userAuth?.isLoggedIn;

  const { data, isLoading } = useQuery({
    queryKey: ['mg-beta-eligibility', userAuth?.isLoggedIn],
    queryFn: async () => {
      if (!userAuth?.isLoggedIn) {
        return { is_eligible: false, has_opted_in: false };
      }

      try {
        const url =
          'authentication/launch/betaeligibility/motiongraphics/DisplayPopup/motiongraphics';

        const response = await api.get<MGBetaEligibilityResponse>(url, {
          withCredentials: true,
          headers: {
            'X-Skip-CDN': 'true',
          },
        });

        handleLocalStorageAndCookies({
          motionGraphics: response.data.has_opted_in,
        });

        return response.data;
      } catch (err) {
        return { is_eligible: false, has_opted_in: false };
      }
    },
    staleTime: 1000 * 60 * 60,
    gcTime: 1000 * 60 * 60,
    enabled: isEnabled,
    retry: false,
  });

  const { mutateAsync: updateOptIn } = useMutation({
    mutationFn: async (status: MGBetaStatus) => {
      const response = await api.post<{ message: string }>(
        `authentication/launch/betaeligibility/motiongraphics/UpdateBetaEligibility/motiongraphics/${status}`,
        {},
        {
          withCredentials: true,
        },
      );

      return response.data;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['mg-beta-eligibility'] });
    },
  });

  return {
    isEligible: data?.is_eligible ?? false,
    hasOptedIn: data?.has_opted_in ?? false,
    isLoading: userAuth?.hasLoaded === false || isLoading,
    hasOptedInMotionGraphics:
      hasOptedInMotionGraphics ?? data?.has_opted_in ?? false,
    updateOptIn,
  };
};
