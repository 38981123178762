import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

import type { ReactNode } from 'react';

interface PopupActionsProps {
  children: ReactNode;
  className?: string;
  reoccurring?: boolean;
  onRemindLater?: () => void;
  onNotInterested?: () => void;
}

export const PopupActions = ({
  children,
  className,
  reoccurring,
  onRemindLater,
  onNotInterested,
}: PopupActionsProps) => {
  return (
    <div className={cn('flex flex-col gap-4 mt-8 pt-6 pb-5', className)}>
      {reoccurring ? (
        <>
          {children}
          <div className="flex justify-between w-full gap-3">
            <Button
              variant="outline"
              onClick={onRemindLater}
              className="flex-1"
              border
            >
              Remind me later
            </Button>
            <Button
              variant="outline"
              onClick={onNotInterested}
              className="flex-1"
              border
            >
              No thanks
            </Button>
          </div>
        </>
      ) : (
        <div className="flex items-center justify-end gap-2 w-full">
          {children}
        </div>
      )}
    </div>
  );
};

export type { PopupActionsProps };
