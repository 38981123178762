import { zodResolver } from '@hookform/resolvers/zod';
import { GoogleLogin } from '@react-oauth/google';
import { useContext, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  checkRecaptchaV3,
  googleSignInActions,
  googleSignIn as onGoogleSignIn,
  resetPasswordRequest,
  setGoogleLoginSuccess,
} from '@/actions/authenticationAction';
import { getContentTypes, getVibeTypes } from '@/actions/dataCaptureActions';
import { updateActiveCampaignContact } from '@/api/activecampaign/update-contact';
import { setAuthToken } from '@/auth';
import Error from '@/components/Form/Error';
import Form from '@/components/Form/Form';
import Input from '@/components/Form/Input';
import Label from '@/components/Form/Label';
import Modal from '@/components/Modals/Core';
import { useSidebarModalContext } from '@/components/Modals/Sidebar/context/SidebarModalContext';
import useDirectionalAnimation from '@/components/Modals/Sidebar/hooks/useDirectionalAnimation';
import { SignUpModalScreenName } from '@/components/Modals/SignUp/types';
import RecaptchaBranding from '@/components/RecaptchaBranding';
import { useScreenContext } from '@/components/SteppedDialog/context/ScreenContext';
import { T } from '@/components/T';
import { Button, Loading } from '@/components/ui/button';
import { useHypertune } from '@/generated/hypertune.react';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppState } from '@/hooks/useAppState';
import { useSettings } from '@/hooks/useSettings';
import { useTranslate } from '@/hooks/useTranslate';
import { UserStateContext } from '@/providers/UserStateProvider';
import { getReferralCode, validateReferralCode } from '@/utils/helpers';

import { LoginModalScreenName } from './types';
import onMakeLoginAttempt from './util/makeLoginAttempt';

import type { CredentialResponse } from '@react-oauth/google';

const schema = z.object({
  email: z
    .string()
    .email({ message: 'Invalid email address' })
    .min(1, { message: 'Email is required' }),
  password: z.string().min(1, { message: 'Password is required' }),
});

type FormData = z.infer<typeof schema>;

const LoginModalScreens = () => {
  const { setScreen } = useScreenContext();
  const animationProps = useDirectionalAnimation();
  const { invalidateSettings } = useSettings();
  const userAuth = useAppState('userAuth');
  const { t } = useTranslate();
  const { setUserState, userState } = useContext(UserStateContext);
  const [loginError, setLoginError] = useState<string | false>(false);
  const { onOpenChange } = useSidebarModalContext();
  const dispatch = useAppDispatch();

  const displayRecaptchaV2 = () =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    dispatch({ type: 'SHOW_RECAPTCHA_V2', showRecaptchaV2: true });

  // Google Sign In
  const [googleLoading, setGoogleLoading] = useState(false);
  const [googleLoginFailure, setGoogleLoginFailure] = useState('');

  const googleLoginErrorMessage =
    googleLoginFailure || userAuth?.googleLoginFailure;

  // Regular Sign In
  const [showRecaptchaV2, setShowRecaptchaV2] = useState(false);
  const [recaptchaV2Token, setRecaptchaV2Token] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const hypertune = useHypertune();

  const quickLogin = localStorage.getItem('quick_login');

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: quickLogin ?? '',
      password: '',
    },
  });

  const loginEmail = watch('email');

  const handlePostLogin = () => {
    // Close the modal
    onOpenChange(false);

    // TODO: support post login redirect / open different modal
  };

  useEffect(() => {
    if (localStorage.getItem('is_google_user') !== '1' && !!quickLogin) {
      setValue('email', quickLogin);
    }
  }, [quickLogin, setValue]);

  const handleGoogleSignInSuccess = async (response: CredentialResponse) => {
    const { clientId, credential, select_by } = response;

    setGoogleLoading(true);

    const payload = {
      client_id: clientId,
      credential,
      select_by,
      access_token: 'accessToken',
      affiliate_code: localStorage.getItem('as_code'),
      partner_code: localStorage.getItem('partner_code'),
      user_referral_code: getReferralCode(),
      rcm: validateReferralCode(),
      share_name_referrer: sessionStorage.getItem('name_consent'),
      ab_seed: userState.abSeed,
      ab_timestamp: userState.abTimestamp,
      rt_code: localStorage.getItem('rt_code'),
      email: 'email',
      impact_clickid: localStorage.getItem('irclickid'),
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    await onGoogleSignIn(payload).then((response) => {
      setLoginError(false);

      if (response.status === 'success') {
        const { isNewUser, contents, vibes, email } = response;

        const loginPayload = {
          credential,
          email,
        };

        fetch(
          `${process.env.NEXT_PUBLIC_API}authentication/googleloginsimple`,
          {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(loginPayload),
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
          .then((res) => {
            if (res.ok) {
              updateActiveCampaignContact();
              getContentTypes(contents);
              getVibeTypes(vibes);
              googleSignInActions(response, setUserState);
              invalidateSettings();
              setGoogleLoading(false);
              handlePostLogin();
              setGoogleLoginSuccess(true, !!isNewUser, true);

              if (isNewUser) {
                hypertune.register({
                  args: {
                    type: 'Google',
                  },
                });
              }
            } else {
              setGoogleLoading(false);
              res
                .text()
                .then((message) => {
                  setGoogleLoginFailure(message);
                })
                .catch((error) => {
                  console.error('Error parsing response message:', error);
                  setGoogleLoginFailure('An unexpected error occurred.');
                });
            }
          })
          .catch((error) => {
            console.error('Error during Google login:', error);
            setGoogleLoading(false);
            setGoogleLoginFailure('An unexpected error occurred.');
          });
      } else {
        // TODO: Something went wrong, hide loader and show error.
        setGoogleLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setGoogleLoginFailure(response.message);
      }
    });
  };

  // Should this do something? - SB
  const handleGoogleSignInError = () => {
    console.warn('Google Sign In Error');
  };

  const handleLoginAttempt = async (data: FormData) => {
    setLoginError(false);
    setGoogleLoginFailure('');

    const recaptchaVersion = showRecaptchaV2 ? '2' : '3';
    let recaptchaToken;

    if (showRecaptchaV2) {
      recaptchaToken = recaptchaV2Token;
    } else {
      recaptchaToken = await checkRecaptchaV3('signup');
    }

    await onMakeLoginAttempt({
      _username: data.email,
      _password: data.password,
      recaptchaToken,
      recaptchaVersion,
    }).then((json) => {
      if (json?.status === 'error' || json?.status === 500) {
        setLoginError(json?.message as string);

        if (json?.code === 10) {
          setShowRecaptchaV2(true);
        }
      } else {
        setAuthToken(json.auth_token as string);

        invalidateSettings();

        handlePostLogin();
      }
    });
  };

  const captchaOnChange = (token: string | null) => {
    setRecaptchaV2Token(token);
  };

  const handleForgotPassword = async () => {
    const recaptchaV3Token = await checkRecaptchaV3('forgotpassword');

    const payload = {
      email: loginEmail,
      recaptcha_token: showRecaptchaV2 ? recaptchaV2Token : recaptchaV3Token,
      recaptcha_version: showRecaptchaV2 ? '2' : '3',
    };

    resetPasswordRequest(payload)
      .then((res) => {
        if (res.success) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setSuccessMessage(res.message);
          setErrorMessage('');
        } else if (res.error) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setErrorMessage(res.error.message);

          if (res.error.showRecaptcha) {
            displayRecaptchaV2();
          }
        }
      })
      .catch((err) => {
        console.warn(
          'Error occurred while trying to reset password:',
          err.message,
        );
      });
  };

  return (
    <>
      <Modal.Screen
        screenName={LoginModalScreenName.LogIn}
        className="px-6 py-12"
        {...animationProps}
      >
        <Modal.Title className="text-black dark:text-white mb-6">
          <T keyName="modal.login.title">Log in</T>
        </Modal.Title>
        <Modal.Description className="sr-only">
          <T keyName="modal.login.description">
            Please enter your email address and password to log in.
          </T>
        </Modal.Description>
        <GoogleLogin
          onSuccess={handleGoogleSignInSuccess}
          onError={handleGoogleSignInError}
          theme="filled_blue"
          text="signin_with"
          shape="pill"
          size="large"
          type="standard"
          width="292px"
          itp_support={false}
        />
        {userAuth?.googleLoginFailure && (
          <div className="alert-general-error login-error my-4">
            <span>{userAuth.googleLoginFailure}</span>
          </div>
        )}
        <p className="w-full mt-6 mb-10 text-center leading-[0] border-solid border-0 border-b border-gray-light-300 dark:border-gray-dark-100">
          <span className="text-black dark:text-white hover:text-black dark:hover:text-white bg-white dark:bg-gray-dark-300 py-0 px-3 font-semibold">
            <T keyName="modal.login.orText">Or</T>
          </span>
        </p>
        <Form onSubmit={handleSubmit(handleLoginAttempt)}>
          <div>
            <Label srOnly>
              <T>Email Address</T>
            </Label>
            <Input
              name="email"
              placeholder={t('modal.login.emailPlaceholder', 'Email Address')}
              register={register}
              hasError={!!errors.email}
            />
            {!!errors.email?.message && (
              <Error>
                <T>{errors.email.message}</T>
              </Error>
            )}
          </div>
          <div>
            <Label srOnly>
              <T>Password</T>
            </Label>
            <Input
              name="password"
              placeholder={t('modal.login.passwordPlaceholder', 'Password')}
              register={register}
              hasError={!!errors.password}
              type="password"
            />
            {!!errors.password?.message && (
              <Error>
                <T>{errors.password.message}</T>
              </Error>
            )}
          </div>
          <div className="-mt-2">
            <button
              className="text-uppbeat text-sm mb-8 cursor-pointer text-right block ml-auto focus:outline-none focus-visible:ring-2 focus-visible:ring-uppbeat focus-visible:ring-opacity-50 rounded-full px-2 -mx-2"
              onClick={() => {
                setScreen('forgot-password');
              }}
              type="button"
            >
              <T keyName="modal.login.forgotPasswordText">
                Lost your password?
              </T>
            </button>
            {!!googleLoginErrorMessage && (
              <div className="alert-general-error login-error mb-4">
                <span>{googleLoginErrorMessage}</span>
              </div>
            )}
            <div className="relative">
              <Button
                id="email-login-btn"
                variant="uppbeat"
                disabled={isSubmitting || googleLoading}
                type="submit"
                className="w-full mb-4"
              >
                <Loading visible={isSubmitting || googleLoading} />
                Log in
              </Button>
            </div>
          </div>
        </Form>
        <p className="text-center text-sm">
          <T keyName="modal.login.noAccountText">Don't have an account?</T>{' '}
          <button
            className="text-sm text-uppbeat focus:outline-none focus-visible:ring-2 focus-visible:ring-uppbeat focus-visible:ring-opacity-50 rounded-full px-2 -mx-2"
            onClick={() => {
              setScreen(SignUpModalScreenName.SignUpCreator);
            }}
          >
            <T keyName="modal.login.signUpText">Sign up</T>
          </button>
        </p>
        {!!loginError && (
          <div className="alert-general-error spacer login-error mt-4">
            <span>
              {!loginError
                ? t(
                    'modal.login.defaultLoginError',
                    'Sorry, something went wrong. Please try again later.',
                  )
                : loginError}
            </span>
          </div>
        )}
        {showRecaptchaV2 && (
          <div className="modal-primary__recaptcha sidebar">
            <ReCAPTCHA
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_V2_KEY}
              onChange={captchaOnChange}
            />
          </div>
        )}
      </Modal.Screen>
      <Modal.Screen
        screenName={LoginModalScreenName.ForgotPassword}
        className="px-6 py-12"
        {...animationProps}
      >
        <Modal.Title className="text-black dark:text-white mb-6">
          <T keyName="forgotPassword.title">Forgot password?</T>
        </Modal.Title>
        <Modal.Description className="mb-4">
          <T keyName="forgotPassword.description">
            Please enter your email address below. You will then receive a link
            to create a new password via email.
          </T>
        </Modal.Description>
        <div className="flex flex-col gap-4">
          <div>
            <Label srOnly>
              <T>Email Address</T>
            </Label>
            <Input
              name="email"
              placeholder={t(
                'forgotPassword.emailPlaceholder',
                'Email Address',
              )}
              register={register}
              hasError={!!errors.email}
            />
            {!!errors.email?.message && (
              <Error>
                <T>{errors.email.message}</T>
              </Error>
            )}
          </div>
          {showRecaptchaV2 && (
            <div>
              <div className="modal-primary__recaptcha">
                <ReCAPTCHA
                  sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_V2_KEY}
                  onChange={captchaOnChange}
                />
              </div>
            </div>
          )}
          <Button
            variant="uppbeat"
            onClick={handleForgotPassword}
            disabled={!loginEmail || !!successMessage}
            className="w-full"
          >
            Send password reset email
          </Button>
          <button
            className="text-sm text-uppbeat focus:outline-none focus-visible:ring-2 focus-visible:ring-uppbeat focus-visible:ring-opacity-50 rounded-full mx-auto px-2"
            onClick={() => {
              setScreen(LoginModalScreenName.LogIn);
            }}
          >
            <T keyName="forgotPassword.backToLoginText">Back to log in</T>
          </button>
          <RecaptchaBranding className="captcha-text" />
          {!!successMessage && (
            <div className="alert-general-success">{successMessage}</div>
          )}
          {!!errorMessage && (
            <div className="alert-general-error">{errorMessage}</div>
          )}
        </div>
      </Modal.Screen>
    </>
  );
};

export default LoginModalScreens;
